import { TextInput, TextField, DateTimeInput, BooleanInput, SimpleForm, Labeled } from "react-admin";
import { Grid } from "@mui/material";

const WFeedbackEditable = () => (
    <SimpleForm>
        <Grid container columnSpacing={2} sx={{ p: 2 }}>
            <Grid item xs={12}>
                <Labeled>
                    <TextField source="id" fullWidth />
                </Labeled>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextInput source="name" variant="outlined" fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextInput source="email" variant="outlined" fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
                <DateTimeInput source="created_at" variant="outlined" fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
                <BooleanInput source="resolved" variant="outlined" fullWidth />
            </Grid>
            <Grid item xs={12}>
                <TextInput source="text" variant="outlined" multiline fullWidth />
            </Grid>
        </Grid>
    </SimpleForm>
);

export default WFeedbackEditable;
