import { Datagrid, List, TextField, DateField, NumberField, TopToolbar } from "react-admin";
import UIDeleteLogsButton from "../../ui/UIDeleteLogsButton";

const ActionsBar = () => (
    <TopToolbar>
        <UIDeleteLogsButton />
    </TopToolbar>
);

const WLogList = () => {
    return (
        <List exporter={false} actions={<ActionsBar />}>
            <Datagrid
                bulkActionButtons={false}
                rowClick="show"
                sx={{
                    "& .RaDatagrid-headerCell": {
                        fontWeight: "bold"
                    }
                }}>
                <TextField source="id" />
                <TextField source="level" />
                <DateField showTime={true} source="created_at" />
                <NumberField source="duration" />
                <TextField source="request_uri" />
                <TextField source="request_protocol" />
                <TextField source="request_method" />
                <TextField source="request_path" />
                <TextField source="request_host" />
                <NumberField source="request_size" />
                <TextField source="remote_ip" />
                <TextField source="remote_port" />
                <NumberField source="response_status_code" />
                <NumberField source="response_size" />
            </Datagrid>
        </List>
    );
};

export default WLogList;
