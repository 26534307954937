import { useRef, useState, useEffect } from "react";
import mapboxGl from "mapbox-gl";
import { useRecordContext } from "react-admin";
import { get } from "lodash";

import * as turf from "@turf/turf";

const POINT_LAYER_ID = "point";
const AREA_LAYER_ID = "area";
const AREA_FILL_LAYER_ID = "area-fill";
const DATA_SOURCE_ID = "data-source";

const UIMapboxField = ({ source, type = "point" }) => {
    const record = useRecordContext();
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [state, setState] = useState(null);
    const [ready, setReady] = useState(false);

    const getCoordinates = value => {
        if (value?.geometry?.type === "Polygon") {
            const polygon = turf.polygon(value.geometry.coordinates);
            const centroid = turf.centroid(polygon);
            return centroid.geometry.coordinates;
        } else if (value?.geometry?.type === "Point") {
            return value.geometry.coordinates;
        }
        return [44.70674790230134, 24.299094216644733]; // Saudi Arabia
    };

    useEffect(() => {
        if (record && source) {
            const value = get(record, source);
            setState(value);
            if (map?.current) {
                map.current.setCenter(getCoordinates(value));
            }
        }
    }, [record, source, ready]);

    useEffect(() => {
        const center = getCoordinates(state);
        if (map && !map.current) {
            map.current = new mapboxGl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/mapbox/streets-v11",
                center: center,
                zoom: 5
            });
            map.current.on("load", () => {
                map.current.addSource(DATA_SOURCE_ID, {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: []
                    }
                });
                if (type === "point") {
                    map.current.addLayer({
                        id: POINT_LAYER_ID,
                        type: "circle",
                        source: DATA_SOURCE_ID,
                        filter: ["!", ["has", "point_count"]],
                        paint: {
                            "circle-color": "#5D9B1F",
                            "circle-radius": 7,
                            "circle-stroke-width": 1.21429,
                            "circle-stroke-color": "#fff"
                        }
                    });
                } else if (type === "polygon") {
                    map.current.addLayer({
                        id: AREA_LAYER_ID,
                        type: "line",
                        source: DATA_SOURCE_ID,
                        layout: {
                            "line-join": "round",
                            "line-cap": "round"
                        },
                        paint: {
                            "line-color": "#90C900",
                            "line-width": 2
                        }
                    });
                    map.current.addLayer({
                        id: AREA_FILL_LAYER_ID,
                        type: "fill",
                        source: DATA_SOURCE_ID,
                        paint: {
                            "fill-color": "#90C900",
                            "fill-opacity": 0.13,
                            "fill-outline-color": "green" //do we need this style?
                        }
                    });
                }

                setReady(true);
            });
        } else {
            map.current.setCenter(center);
            const source = map.current.getSource(DATA_SOURCE_ID);
            if (source && state) {
                source.setData({
                    type: "FeatureCollection",
                    features: [state]
                });
                map.current.setZoom(11);
            }
        }
    });

    return (
        <div>
            <div ref={mapContainer} style={{ height: "400px" }} />
        </div>
    );
};

export default UIMapboxField;
