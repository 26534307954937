import { API_URL } from "../common";
import httpClient from "../../services/http";
import BaseDataProvider from "./base";

class FeedbackDataProvider extends BaseDataProvider {
    async getList(resource, params) {
        const { filter, sort } = params;
        const { page, perPage } = params.pagination;
        const url = new URL(`${API_URL}/${resource}`);
        url.search = new URLSearchParams({
            ...filter,
            sort_direction: sort?.order,
            sort_by: sort.field
        }).toString();
        const { json } = await httpClient(url, {
            headers: new Headers({
                "x-offset": (page - 1) * perPage,
                "x-count": perPage
            })
        });
        return {
            data: json.feedbacks,
            total: json.total_count
        };
    }
}

export default FeedbackDataProvider;
