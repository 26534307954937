import {
    TextInput,
    TabbedForm,
    FormTab,
    TranslatableInputs,
    NumberInput,
    useUpdate,
    useDelete,
    useNotify,
    useRefresh,
    useRecordContext,
    Labeled,
    ReferenceArrayInput,
    useTranslate,
    Button,
    BooleanInput,
    RecordContextProvider,
    FunctionField,
    required
} from "react-admin";
import { Grid, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { supportedLocales } from "../../../../data/providers/i18n";
import WAccountShowable from "../account/WAccountShowable";
import WSpecieShowable from "../specie/WSpecieShowable";
import WLocationShowable from "../location/WLocationShowable";
import WInitiativeShowable from "../initiative/WInitiativeShowable";
import WAccountList from "../../lists/WAccountList";
import WSpecieList from "../../lists/WSpecieList";
import WLocationList from "../../lists/WLocationList";
import WInitiativeList from "../../lists/WInitiativeList";
import React from "react";

const WOrderEditable = () => {
    const record = useRecordContext();
    const translate = useTranslate();

    const notify = useNotify();
    const refresh = useRefresh();
    const [update] = useUpdate();
    const [deleteOne] = useDelete();

    const onUserSelected = async (user, type, id) => {
        const meta = {};
        Reflect.set(meta, type, true);
        const data = {};
        Reflect.set(data, type, user);
        await update(
            "order",
            {
                id: id,
                meta: meta,
                data: data
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("ra.notification.updated", {
                        messageArgs: { smart_count: 1 }
                    });
                },
                onError: error => {
                    if (error.status === 400) {
                        notify("other.errors.badRequest", {
                            type: "warning",
                            messageArgs: {
                                reason: error.body?.detail ?? error.message
                            }
                        });
                    } else {
                        notify("other.errors.empty", {
                            type: "warning",
                            messageArgs: {
                                reason: error.message
                            }
                        });
                    }
                }
            }
        );
    };

    const onOwnerSelected = async owner => {
        if (owner && record?.id) {
            await onUserSelected(owner, "owner", record?.id);
        }
    };

    const onCreatorSelected = async creator => {
        if (creator && record?.id) {
            await onUserSelected(creator, "creator", record?.id);
        }
    };

    const onUserSelectedToDelete = async type => {
        const meta = {};
        Reflect.set(meta, type, true);
        await deleteOne(
            "order",
            {
                id: record?.id,
                meta: meta
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("ra.notification.updated", {
                        messageArgs: { smart_count: 1 }
                    });
                },
                onError: error => {
                    if (error.status === 400) {
                        notify("other.errors.badRequest", {
                            type: "warning",
                            messageArgs: {
                                reason: error.body?.detail ?? error.message
                            }
                        });
                    } else {
                        notify("other.errors.empty", {
                            type: "warning",
                            messageArgs: {
                                reason: error.message
                            }
                        });
                    }
                }
            }
        );
    };

    const onOwnerSelectedToDelete = async () => await onUserSelectedToDelete("owner");

    const onCreatorSelectedToDelete = async () => await onUserSelectedToDelete("creator");

    const onSpecieSelected = async specie => {
        await update(
            "order",
            {
                id: record?.id,
                meta: { specie: true },
                data: { specie: specie }
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("ra.notification.updated", {
                        messageArgs: { smart_count: 1 }
                    });
                },
                onError: error => {
                    if (error.status === 400) {
                        notify("other.errors.badRequest", {
                            type: "warning",
                            messageArgs: {
                                reason: error.body?.detail ?? error.message
                            }
                        });
                    } else {
                        notify("other.errors.empty", {
                            type: "warning",
                            messageArgs: {
                                reason: error.message
                            }
                        });
                    }
                }
            }
        );
    };

    const onSpecieSelectedToDelete = async () => {
        await deleteOne(
            "order",
            {
                id: record?.id,
                meta: { specie: true }
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("ra.notification.updated", {
                        messageArgs: { smart_count: 1 }
                    });
                },
                onError: error => {
                    if (error.status === 400) {
                        notify("other.errors.badRequest", {
                            type: "warning",
                            messageArgs: {
                                reason: error.body?.detail ?? error.message
                            }
                        });
                    } else {
                        notify("other.errors.empty", {
                            type: "warning",
                            messageArgs: {
                                reason: error.message
                            }
                        });
                    }
                }
            }
        );
    };

    const onLocationSelected = async location => {
        await update(
            "order",
            {
                id: record?.id,
                meta: { location: true },
                data: { location: location }
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("ra.notification.updated", {
                        messageArgs: { smart_count: 1 }
                    });
                },
                onError: error => {
                    if (error.status === 400) {
                        notify("other.errors.badRequest", {
                            type: "warning",
                            messageArgs: {
                                reason: error.body?.detail ?? error.message
                            }
                        });
                    } else {
                        notify("other.errors.empty", {
                            type: "warning",
                            messageArgs: {
                                reason: error.message
                            }
                        });
                    }
                }
            }
        );
    };

    const onLocationSelectedToDelete = async () => {
        await deleteOne(
            "order",
            {
                id: record?.id,
                meta: { location: true }
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("ra.notification.updated", {
                        messageArgs: { smart_count: 1 }
                    });
                },
                onError: error => {
                    if (error.status === 400) {
                        notify("other.errors.badRequest", {
                            type: "warning",
                            messageArgs: {
                                reason: error.body?.detail ?? error.message
                            }
                        });
                    } else {
                        notify("other.errors.empty", {
                            type: "warning",
                            messageArgs: {
                                reason: error.message
                            }
                        });
                    }
                }
            }
        );
    };

    const onInitiativeSelected = async initiative => {
        await update(
            "order",
            {
                id: record?.id,
                meta: { initiative: true },
                data: { initiative: initiative }
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("ra.notification.updated", {
                        messageArgs: { smart_count: 1 }
                    });
                },
                onError: error => {
                    if (error.status === 400) {
                        notify("other.errors.badRequest", {
                            type: "warning",
                            messageArgs: {
                                reason: error.body?.detail ?? error.message
                            }
                        });
                    } else {
                        notify("other.errors.empty", {
                            type: "warning",
                            messageArgs: {
                                reason: error.message
                            }
                        });
                    }
                }
            }
        );
    };

    const onInitiativeSelectedToDelete = async () => {
        await deleteOne(
            "order",
            {
                id: record?.id,
                meta: { initiative: true }
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("ra.notification.updated", {
                        messageArgs: { smart_count: 1 }
                    });
                },
                onError: error => {
                    if (error.status === 400) {
                        notify("other.errors.badRequest", {
                            type: "warning",
                            messageArgs: {
                                reason: error.body?.detail ?? error.message
                            }
                        });
                    } else {
                        notify("other.errors.empty", {
                            type: "warning",
                            messageArgs: {
                                reason: error.message
                            }
                        });
                    }
                }
            }
        );
    };

    return (
        <TabbedForm syncWithLocation={false}>
            <FormTab label="resources.order.tabs.common">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={6}>
                        <TextInput source="given_name" validate={[required()]} fullWidth variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <NumberInput source="trees_ordered" validate={[required()]} fullWidth variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextInput source="payment_brand" fullWidth variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <NumberInput source="amount" validate={[required()]} fullWidth variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BooleanInput source="is_payed" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BooleanInput source="is_sms_sent" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BooleanInput source="is_gift" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextInput source="checkout_id" fullWidth variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextInput source="recipient_email" fullWidth variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextInput source="recipient_phone" fullWidth variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextInput source="email" fullWidth variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextInput source="phone" fullWidth variant="outlined" />
                    </Grid>
                    <Grid item xs={12}>
                        <TranslatableInputs locales={supportedLocales}>
                            <TextInput source="certificate_url" fullWidth variant="outlined" />
                        </TranslatableInputs>
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label="resources.order.tabs.users" disabled={!record?.id}>
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={6}>
                        {record?.owner ? (
                            <Labeled source="owner">
                                <RecordContextProvider value={record?.owner}>
                                    <WAccountShowable />
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="error"
                                        startIcon={<Delete />}
                                        label="ra.action.delete"
                                        onClick={onOwnerSelectedToDelete}
                                    />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="owner">
                                <Typography variant="subtitle1">
                                    {translate("resources.order.other.noAccount")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {record?.creator ? (
                            <Labeled source="creator" fullWidth>
                                <RecordContextProvider value={record?.creator}>
                                    <WAccountShowable />
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="error"
                                        startIcon={<Delete />}
                                        label="ra.action.delete"
                                        onClick={onCreatorSelectedToDelete}
                                    />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="creator">
                                <Typography variant="subtitle1">
                                    {translate("resources.order.other.noAccount")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled label="resources.order.other.addUser" sx={{ marginTop: "2em" }}>
                            <ReferenceArrayInput source="user_ids" reference="account">
                                <WAccountList withoutToolbar>
                                    <FunctionField
                                        render={record => (
                                            <Button
                                                label="resources.order.other.setOwner"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    onOwnerSelected(record);
                                                }}
                                            />
                                        )}
                                    />
                                    <FunctionField
                                        render={record => (
                                            <Button
                                                label="resources.order.other.setCreator"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    onCreatorSelected(record);
                                                }}
                                            />
                                        )}
                                    />
                                </WAccountList>
                            </ReferenceArrayInput>
                        </Labeled>
                    </Grid>
                </Grid>
            </FormTab>

            <FormTab label="resources.order.tabs.specie" disabled={!record?.id}>
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        {record?.specie ? (
                            <Labeled source="specie" fullWidth>
                                <RecordContextProvider value={record?.specie}>
                                    <WSpecieShowable />
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="error"
                                        startIcon={<Delete />}
                                        label="ra.action.delete"
                                        onClick={onSpecieSelectedToDelete}
                                    />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="specie">
                                <Typography variant="subtitle1">
                                    {translate("resources.order.other.noSpecie")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled label="resources.order.other.addSpecie" fullWidth sx={{ marginTop: "2em" }}>
                            <ReferenceArrayInput source="specie_ids" reference="specie">
                                <WSpecieList withoutToolbar onSpecieSelectButtonClick={onSpecieSelected} />
                            </ReferenceArrayInput>
                        </Labeled>
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label="resources.order.tabs.location" disabled={!record?.id}>
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        {record?.location ? (
                            <Labeled source="location" fullWidth>
                                <RecordContextProvider value={record?.location}>
                                    <WLocationShowable />
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="error"
                                        startIcon={<Delete />}
                                        label="ra.action.delete"
                                        onClick={onLocationSelectedToDelete}
                                    />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="location">
                                <Typography variant="subtitle1">
                                    {translate("resources.order.other.noLocation")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled label="resources.order.other.addLocation" fullWidth sx={{ marginTop: "2em" }}>
                            <ReferenceArrayInput source="location_ids" reference="location">
                                <WLocationList withoutToolbar onLocationSelectButtonClick={onLocationSelected} />
                            </ReferenceArrayInput>
                        </Labeled>
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label="resources.order.tabs.initiative" disabled={!record?.id}>
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        {record?.initiative ? (
                            <Labeled source="initiative" fullWidth>
                                <RecordContextProvider value={record?.initiative}>
                                    <WInitiativeShowable />
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="error"
                                        startIcon={<Delete />}
                                        label="ra.action.delete"
                                        onClick={onInitiativeSelectedToDelete}
                                    />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="initiative">
                                <Typography variant="subtitle1">
                                    {translate("resources.order.other.noInitiative")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled label="resources.order.other.addInitiative" fullWidth sx={{ marginTop: "2em" }}>
                            <ReferenceArrayInput source="initiative_ids" reference="initiative">
                                <WInitiativeList withoutToolbar onInitiativeSelectButtonClick={onInitiativeSelected} />
                            </ReferenceArrayInput>
                        </Labeled>
                    </Grid>
                </Grid>
            </FormTab>
        </TabbedForm>
    );
};

export default WOrderEditable;
