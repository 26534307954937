import { SimpleShowLayout, TextField, DateField, NumberField } from "react-admin";
import UIJsonField from "../../../ui/fields/UIJsonField";

const WLogShowable = () => (
    <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="level" />
        <DateField source="created_at" />
        <NumberField source="duration" />
        <TextField source="request_uri" />
        <TextField source="request_protocol" />
        <TextField source="request_method" />
        <TextField source="request_path" />
        <TextField source="request_host" />
        <NumberField source="request_size" />
        <TextField source="request_content_type" />
        <UIJsonField source="request_headers" />
        <UIJsonField source="request_body" />
        <TextField source="request_direction" />
        <TextField source="remote_ip" />
        <TextField source="remote_port" />
        <NumberField source="response_status_code" />
        <NumberField source="response_size" />
        <UIJsonField source="response_headers" />
        <UIJsonField source="response_body" />
        <TextField source="request_stack_trace" />
        <TextField source="response_stack_trace" />
    </SimpleShowLayout>
);

export default WLogShowable;
