import { API_URL } from "../common";
import jwt_decode from "jwt-decode";

const authProvider = {
    login: ({ username, password }) => {
        const request = new Request(`${API_URL}/auth/signin_username`, {
            method: "POST",
            body: JSON.stringify({ username, password }),
            headers: new Headers({ "Content-Type": "application/json" })
        });
        return fetch(request)
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error(response.statusText);
                }
            })
            .then(auth => {
                localStorage.setItem("auth", JSON.stringify(auth));
            })
            .catch(() => {
                throw new Error("Network error");
            });
    },
    logout: () => {
        localStorage.removeItem("auth");
        return Promise.resolve();
    },
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem("auth");
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        const token = JSON.parse(localStorage.getItem("auth"))?.token;
        if (token) {
            const accountInfo = jwt_decode(token)?.sub;
            if (["admin", "root"].includes(accountInfo.role)) {
                return Promise.resolve();
            } else {
                return Promise.reject();
            }
        } else {
            return Promise.reject();
        }
    },
    getIdentity: () => {
        const token = JSON.parse(localStorage.getItem("auth"))?.token;
        if (token) {
            const accountInfo = jwt_decode(token)?.sub;
            return Promise.resolve({
                id: accountInfo.id,
                fullName: accountInfo.fullname
            });
        } else {
            return Promise.reject("Wrong token");
        }
    },
    getPermissions: () => {
        const token = JSON.parse(localStorage.getItem("auth"))?.token;
        if (token) {
            const accountInfo = jwt_decode(token)?.sub;
            return Promise.resolve(accountInfo.role);
        } else {
            return Promise.reject("Wrong token");
        }
    }
};

export default authProvider;
