import { Show } from "react-admin";
import WFeedbackShowable from "./WFeedbackShowable.jsx";

const WFeedbackShow = () => {
    return (
        <Show>
            <WFeedbackShowable />
        </Show>
    );
};

export default WFeedbackShow;
