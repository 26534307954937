import { Show } from "react-admin";
import WLocationShowable from "./WLocationShowable";

const WLocationShow = () => (
    <Show>
        <WLocationShowable />
    </Show>
);

export default WLocationShow;
