import {
    Datagrid,
    TextField,
    List,
    FilterLiveSearch,
    useLocaleState,
    TopToolbar,
    CreateButton,
    FunctionField,
    Button
} from "react-admin";
import { Card, CardContent, Box } from "@mui/material";

const FilterSidebar = ({ withoutToolbar }) => (
    <Box
        sx={{
            display: {
                xs: "none",
                sm: "block"
            },
            order: -1,
            width: "15em",
            marginRight: "1em",
            marginTop: withoutToolbar ? "0" : "76px"
        }}>
        <Card>
            <CardContent>
                <FilterLiveSearch source="title" variant="outlined" label="resources.location.fields.title" />
                <FilterLiveSearch source="country" variant="outlined" label="resources.location.fields.country" />
                <FilterLiveSearch source="city" variant="outlined" label="resources.location.fields.city" />
            </CardContent>
        </Card>
    </Box>
);

const LocationActions = () => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

const WLocationList = ({ onLocationSelectButtonClick, withoutToolbar = false }) => {
    const [locale] = useLocaleState();
    return (
        <List
            exporter={false}
            aside={<FilterSidebar withoutToolbar={withoutToolbar} />}
            actions={!withoutToolbar && <LocationActions />}>
            <Datagrid
                bulkActionButtons={false}
                sx={{
                    "& .RaDatagrid-headerCell": {
                        fontWeight: "bold"
                    }
                }}
                rowClick="show">
                <TextField source="id" />
                <TextField source={`title.${locale}`} />
                <TextField source={`country.${locale}`} />
                <TextField source={`city.${locale}`} />
                {onLocationSelectButtonClick && (
                    <FunctionField
                        render={record => (
                            <Button
                                label="ra.action.add"
                                onClick={e => {
                                    e.stopPropagation();
                                    onLocationSelectButtonClick?.call(null, record);
                                }}
                            />
                        )}
                    />
                )}
            </Datagrid>
        </List>
    );
};

export default WLocationList;
