import { Admin, Resource, combineDataProviders, Layout } from "react-admin";
import i18nProvider from "./data/providers/i18n";
import authProvider from "./data/providers/auth";
import BaseDataProvider from "./data/providers/base";
import AccountDataProvider from "./data/providers/account";
import SpecieDataProvider from "./data/providers/specie";
import LocationDataProvider from "./data/providers/location";
import InitiativeDataProvider from "./data/providers/initiative";
import OrderDataProvider from "./data/providers/order";
import FeedbackDataProvider from "./data/providers/feedback";
import TreeDataProvider from "./data/providers/tree";
import LogDataProvider from "./data/providers/log";
import WAccountList from "./components/widgets/lists/WAccountList";
import WAccountShow from "./components/widgets/forms/account/WAccountShow";
import WAccountCreate from "./components/widgets/forms/account/WAccountCreate";
import WAccountEdit from "./components/widgets/forms/account/WAccountEdit";
import WSpecieList from "./components/widgets/lists/WSpecieList";
import WSpecieShow from "./components/widgets/forms/specie/WSpecieShow";
import WSpecieCreate from "./components/widgets/forms/specie/WSpecieCreate";
import WSpecieEdit from "./components/widgets/forms/specie/WSpecieEdit";
import WLocationList from "./components/widgets/lists/WLocationList";
import WLocationShow from "./components/widgets/forms/location/WLocationShow";
import WLocationCreate from "./components/widgets/forms/location/WLocationCreate";
import WLocationEdit from "./components/widgets/forms/location/WLocationEdit";
import WInitiativeList from "./components/widgets/lists/WInitiativeList";
import WInitiativeShow from "./components/widgets/forms/initiative/WInitiativeShow";
import WInitiativeCreate from "./components/widgets/forms/initiative/WInitiativeCreate";
import WInitiativeEdit from "./components/widgets/forms/initiative/WInitiativeEdit";
import WOrderList from "./components/widgets/lists/WOrderList";
import WOrdersShow from "./components/widgets/forms/order/WOrderShow";
import WOrderCreate from "./components/widgets/forms/order/WOrderCreate";
import WOrderEdit from "./components/widgets/forms/order/WOrderEdit";
import WFeedbackList from "./components/widgets/lists/WFeedbackList";
import WFeedbackShow from "./components/widgets/forms/feedback/WFeedbackShow";
import WFeedbackEdit from "./components/widgets/forms/feedback/WFeedbackEdit";
import WLogList from "./components/widgets/lists/WLogList";
import WLogShow from "./components/widgets/forms/log/WLogShow";
import WTreeList from "./components/widgets/lists/WTreeList";
import WTreeShow from "./components/widgets/forms/tree/WTreeShow";
import WTreeEdit from "./components/widgets/forms/tree/WTreeEdit";
import UIAppBar from "./components/ui/UIAppBar";
import { Group, Spa, LocationOn, NaturePeople, Sell, Feedback, Nature, Storage } from "@mui/icons-material";

const dataProvider = combineDataProviders(resource => {
    switch (resource) {
        case "account":
            return new AccountDataProvider();
        case "specie":
            return new SpecieDataProvider();
        case "location":
            return new LocationDataProvider();
        case "initiative":
            return new InitiativeDataProvider();
        case "order":
            return new OrderDataProvider();
        case "feedback":
            return new FeedbackDataProvider();
        case "tree":
            return new TreeDataProvider();
        case "log":
            return new LogDataProvider();
        default:
            return new BaseDataProvider();
    }
});

const UILayout = props => <Layout {...props} appBar={UIAppBar} />;

const App = () => (
    <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        layout={UILayout}
        requireAuth>
        {permissions => (
            <>
                <Resource
                    name="account"
                    list={WAccountList}
                    show={WAccountShow}
                    create={WAccountCreate}
                    edit={WAccountEdit}
                    icon={Group}
                />
                <Resource
                    name="specie"
                    list={WSpecieList}
                    show={WSpecieShow}
                    create={WSpecieCreate}
                    edit={WSpecieEdit}
                    icon={Spa}
                />
                <Resource
                    name="location"
                    list={WLocationList}
                    show={WLocationShow}
                    create={WLocationCreate}
                    edit={WLocationEdit}
                    icon={LocationOn}
                />
                <Resource
                    name="initiative"
                    list={WInitiativeList}
                    show={WInitiativeShow}
                    create={WInitiativeCreate}
                    edit={WInitiativeEdit}
                    icon={NaturePeople}
                />
                <Resource
                    name="order"
                    list={WOrderList}
                    show={WOrdersShow}
                    create={WOrderCreate}
                    edit={WOrderEdit}
                    icon={Sell}
                />
                <Resource name="tree" list={WTreeList} show={WTreeShow} edit={WTreeEdit} icon={Nature} />
                <Resource
                    name="feedback"
                    list={WFeedbackList}
                    show={WFeedbackShow}
                    edit={WFeedbackEdit}
                    icon={Feedback}
                />
                {permissions === "root" && <Resource name="log" list={WLogList} show={WLogShow} icon={Storage} />}
            </>
        )}
    </Admin>
);

export default App;
