import {
    Datagrid,
    TextField,
    List,
    FunctionField,
    useTranslate,
    useLocaleState,
    DateField,
    EmailField,
    FilterLiveSearch,
    Link,
    TopToolbar,
    CreateButton
} from "react-admin";
import { Typography } from "@mui/material";
import { Card, CardContent, Box } from "@mui/material";

const FilterSidebar = ({ withoutToolbar }) => (
    <Box
        sx={{
            display: {
                xs: "none",
                sm: "block"
            },
            order: -1,
            width: "15em",
            marginRight: "1em",
            marginTop: withoutToolbar ? "0" : "76px"
        }}>
        <Card>
            <CardContent>
                <FilterLiveSearch source="created_by" variant="outlined" label="resources.order.fields.created_by" />
                <FilterLiveSearch source="owned_by" variant="outlined" label="resources.order.fields.owned_by" />
                <FilterLiveSearch source="location" variant="outlined" label="resources.order.fields.location" />
                <FilterLiveSearch source="specie" variant="outlined" label="resources.order.fields.specie" />
            </CardContent>
        </Card>
    </Box>
);

const OrderActions = () => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

const WOrderList = ({ withoutToolbar = false, children }) => {
    const translate = useTranslate();
    const [locale] = useLocaleState();
    return (
        <List
            exporter={false}
            aside={<FilterSidebar withoutToolbar={withoutToolbar} />}
            actions={!withoutToolbar && <OrderActions />}>
            <Datagrid
                bulkActionButtons={false}
                sx={{
                    "& .RaDatagrid-headerCell": {
                        fontWeight: "bold"
                    }
                }}
                rowClick="show">
                <TextField source="id" />
                <FunctionField
                    source="creator"
                    render={record => {
                        if (record?.creator?.id) {
                            return (
                                <Link onClick={e => e.stopPropagation()} to={`/account/${record.creator?.id}/show`}>
                                    <TextField source="creator.username" />
                                </Link>
                            );
                        } else {
                            return (
                                <Typography variant="subtitle1">
                                    {translate("resources.order.other.noAccount")}
                                </Typography>
                            );
                        }
                    }}
                />
                <FunctionField
                    source="owner"
                    render={record => {
                        if (record?.owner?.id) {
                            return (
                                <Link onClick={e => e.stopPropagation()} to={`/account/${record.creator?.id}/show`}>
                                    <TextField source="owner.username" />
                                </Link>
                            );
                        } else {
                            return (
                                <Typography variant="subtitle1">
                                    {translate("resources.order.other.noAccount")}
                                </Typography>
                            );
                        }
                    }}
                />
                <TextField source="given_name" />
                <EmailField source="recipient_email" emptyText={translate("resources.order.other.noEmail")} />
                <TextField source="recipient_phone" emptyText={translate("resources.order.other.noPhone")} />
                <EmailField source="email" emptyText={translate("resources.order.other.noEmail")} />
                <TextField source="phone" emptyText={translate("resources.order.other.noPhone")} />
                <FunctionField
                    source="location"
                    render={record => {
                        if (record?.location?.id) {
                            return (
                                <Link onClick={e => e.stopPropagation()} to={`/location/${record.creator?.id}/show`}>
                                    <TextField source={`location.title.${locale}`} />
                                </Link>
                            );
                        } else {
                            return (
                                <Typography variant="subtitle1">
                                    {translate("resources.order.other.noLocation")}
                                </Typography>
                            );
                        }
                    }}
                />
                <FunctionField
                    source="specie"
                    render={record => {
                        if (record?.specie?.id) {
                            return (
                                <Link onClick={e => e.stopPropagation()} to={`/specie/${record.creator?.id}/show`}>
                                    <TextField source={`specie.common_name.${locale}`} />
                                </Link>
                            );
                        } else {
                            return (
                                <Typography variant="subtitle1">
                                    {translate("resources.order.other.noSpecie")}
                                </Typography>
                            );
                        }
                    }}
                />
                <DateField source="created_at" />
                {children}
            </Datagrid>
        </List>
    );
};

export default WOrderList;
