import { useNotify } from "react-admin";

const useOnFailureMessage = () => {
    const notify = useNotify();
    const onFailure = error => {
        notify("error", {
            type: "warning",
            messageArgs: { error: "" + error.body?.detail || error.message }
        });
    };
    return onFailure;
};
export default useOnFailureMessage;
