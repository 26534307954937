import { API_URL } from "../common";
import httpClient from "../../services/http";
import BaseDataProvider from "./base";

class OrderDataProvider extends BaseDataProvider {
    async getList(resource, params) {
        const { filter, sort } = params;
        const { page, perPage } = params.pagination;
        const url = new URL(`${API_URL}/${resource}`);
        url.search = new URLSearchParams({
            ...filter,
            sort_direction: sort?.order,
            sort_by: sort?.field?.split(".").shift()
        })?.toString();
        const { json } = await httpClient(url, {
            headers: new Headers({
                "x-offset": (page - 1) * perPage,
                "x-count": perPage
            })
        });
        return {
            data: json.orders,
            total: json.total_count
        };
    }

    async update(resource, params) {
        const { owner, creator, specie, location, initiative, ...payload } = params.data;
        if (params.meta?.owner) {
            const { json } = await httpClient(`${API_URL}/${resource}/${params.id}/owner/${owner?.id}`, {
                method: "POST"
            });
            return { data: json };
        } else if (params.meta?.creator) {
            const { json } = await httpClient(`${API_URL}/${resource}/${params.id}/creator/${creator?.id}`, {
                method: "POST"
            });
            return { data: json };
        } else if (params.meta?.specie) {
            const { json } = await httpClient(`${API_URL}/${resource}/${params.id}/specie/${specie?.id}`, {
                method: "POST"
            });
            return { data: json };
        } else if (params.meta?.location) {
            const { json } = await httpClient(`${API_URL}/${resource}/${params.id}/location/${location?.id}`, {
                method: "POST"
            });
            return { data: json };
        } else if (params.meta?.initiative) {
            const { json } = await httpClient(`${API_URL}/${resource}/${params.id}/initiative/${initiative?.id}`, {
                method: "POST"
            });
            return { data: json };
        } else {
            await httpClient(`${API_URL}/${resource}/${params.id}`, {
                method: "PUT",
                body: JSON.stringify(payload)
            });
            return { data: payload };
        }
    }

    async delete(resource, params) {
        if (params.meta?.owner) {
            await httpClient(`${API_URL}/${resource}/${params.id}/owner/`, {
                method: "DELETE"
            });
        } else if (params.meta?.creator) {
            await httpClient(`${API_URL}/${resource}/${params.id}/creator/`, {
                method: "DELETE"
            });
        } else if (params.meta?.specie) {
            await httpClient(`${API_URL}/${resource}/${params.id}/specie/`, {
                method: "DELETE"
            });
        } else if (params.meta?.location) {
            await httpClient(`${API_URL}/${resource}/${params.id}/location/`, {
                method: "DELETE"
            });
        } else if (params.meta?.initiative) {
            await httpClient(`${API_URL}/${resource}/${params.id}/initiative/`, {
                method: "DELETE"
            });
        } else {
            await httpClient(`${API_URL}/${resource}/${params.id}`, {
                method: "DELETE"
            });
        }
        return { data: params };
    }
}

export default OrderDataProvider;
