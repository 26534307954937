import {
    TextField,
    NumberField,
    BooleanField,
    DateField,
    UrlField,
    EmailField,
    Labeled,
    useTranslate,
    TabbedShowLayout,
    Tab,
    TranslatableFields,
    useRecordContext,
    RecordContextProvider
} from "react-admin";
import { supportedLocales } from "../../../../data/providers/i18n";
import { Grid, Typography } from "@mui/material";
import WAccountShowable from "../account/WAccountShowable";
import WSpecieShowable from "../specie/WSpecieShowable";
import WLocationShowable from "../location/WLocationShowable";
import WInitiativeShowable from "../initiative/WInitiativeShowable";

const WOrdersShowable = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    return (
        <TabbedShowLayout syncWithLocation={false}>
            <Tab label="resources.order.tabs.common">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={6}>
                        <Labeled>
                            <TextField source="id" fullWidth />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled>
                            <DateField source="created_at" fullWidth />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled>
                            <TextField source="given_name" fullWidth />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled>
                            <NumberField source="trees_ordered" fullWidth />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled>
                            <BooleanField source="is_payed" fullWidth />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled>
                            <BooleanField source="payment_brand" fullWidth />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled>
                            <BooleanField source="is_gift" fullWidth />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled>
                            <BooleanField source="is_sms_sent" fullWidth />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled>
                            <NumberField source="amount" fullWidth />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled>
                            <TextField source="checkout_id" fullWidth />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled>
                            <EmailField
                                source="recipient_email"
                                fullWidth
                                emptyText={translate("resources.order.other.noEmail")}
                            />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled>
                            <TextField
                                source="recipient_phone"
                                fullWidth
                                emptyText={translate("resources.order.other.noPhone")}
                            />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled>
                            <EmailField
                                source="email"
                                fullWidth
                                emptyText={translate("resources.order.other.noEmail")}
                            />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled>
                            <TextField
                                source="phone"
                                fullWidth
                                emptyText={translate("resources.order.other.noPhone")}
                            />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled fullWidth source="certificate_url">
                            <TranslatableFields locales={supportedLocales}>
                                <UrlField source="certificate_url" fullWidth />
                            </TranslatableFields>
                        </Labeled>
                    </Grid>
                </Grid>
            </Tab>
            <Tab label="resources.order.tabs.users">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={6}>
                        {record?.owner ? (
                            <Labeled source="owner">
                                <RecordContextProvider value={record?.owner}>
                                    <WAccountShowable />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="owner">
                                <Typography variant="subtitle1">
                                    {translate("resources.order.other.noAccount")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {record?.creator ? (
                            <Labeled source="creator" fullWidth>
                                <RecordContextProvider value={record?.creator}>
                                    <WAccountShowable />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="creator">
                                <Typography variant="subtitle1">
                                    {translate("resources.order.other.noAccount")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                </Grid>
            </Tab>
            <Tab label="resources.order.tabs.specie">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        {record?.specie ? (
                            <Labeled source="specie" fullWidth>
                                <RecordContextProvider value={record?.specie}>
                                    <WSpecieShowable />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="specie">
                                <Typography variant="subtitle1">
                                    {translate("resources.order.other.noSpecie")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                </Grid>
            </Tab>
            <Tab label="resources.order.tabs.location">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        {record?.location ? (
                            <Labeled source="location" fullWidth>
                                <RecordContextProvider value={record?.location}>
                                    <WLocationShowable />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="location">
                                <Typography variant="subtitle1">
                                    {translate("resources.order.other.noLocation")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                </Grid>
            </Tab>
            <Tab label="resources.order.tabs.initiative">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        {record?.initiative ? (
                            <Labeled source="initiative" fullWidth>
                                <RecordContextProvider value={record?.initiative}>
                                    <WInitiativeShowable />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="initiative">
                                <Typography variant="subtitle1">
                                    {translate("resources.order.other.noInitiative")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                </Grid>
            </Tab>
        </TabbedShowLayout>
    );
};

export default WOrdersShowable;
