import { API_URL } from "../common";
import httpClient from "../../services/http";
import BaseDataProvider from "./base";

class TreeDataProvider extends BaseDataProvider {
    async getList(resource, params) {
        const { filter, sort } = params;
        const { page, perPage } = params.pagination;
        const url = new URL(`${API_URL}/${resource}`);
        url.search = new URLSearchParams({
            ...{
                ...filter,
                show_all: typeof filter?.show_all === "boolean" ? filter.show_all : true
            },
            sort_direction: sort?.order,
            sort_by: sort.field
        }).toString();
        const { json } = await httpClient(url, {
            headers: new Headers({
                "x-offset": (page - 1) * perPage,
                "x-count": perPage
            })
        });
        return {
            data: json.trees,
            total: json.total_count
        };
    }

    async update(resource, params) {
        const { image, planter, specie, order, ...payload } = params.data;
        if (params.meta?.file && image) {
            const form = new FormData();
            form.append("image", image);
            const { json } = await httpClient(`${API_URL}/${resource}/${params.id}/image`, {
                method: "POST",
                body: form
            });
            return { data: json };
        } else if (params.meta?.planter) {
            const { json } = await httpClient(`${API_URL}/${resource}/${params.id}/planter/${planter?.id}`, {
                method: "POST"
            });
            return { data: json };
        } else if (params.meta?.specie) {
            const { json } = await httpClient(`${API_URL}/${resource}/${params.id}/recognized_specie/${specie?.id}`, {
                method: "POST"
            });
            return { data: json };
        } else if (params.meta?.order) {
            const url = params.meta.auto
                ? `${API_URL}/planter/tree/${params.id}/match_to_order/`
                : `${API_URL}/${resource}/${params.id}/order/${order?.id}`;
            const { json } = await httpClient(url, {
                method: "POST"
            });
            return { data: json };
        } else {
            await httpClient(`${API_URL}/${resource}/${params.id}`, {
                method: "PUT",
                body: JSON.stringify(payload)
            });
            return { data: payload };
        }
    }

    async delete(resource, params) {
        if (params.meta?.imageId) {
            await httpClient(`${API_URL}/${resource}/${params.id}/image/${params.meta?.imageId}`, {
                method: "DELETE"
            });
        } else if (params.meta?.planter) {
            await httpClient(`${API_URL}/${resource}/${params.id}/planter/`, {
                method: "DELETE"
            });
        } else if (params.meta?.specie) {
            await httpClient(`${API_URL}/${resource}/${params.id}/recognized_specie/`, {
                method: "DELETE"
            });
        } else if (params.meta?.order) {
            await httpClient(`${API_URL}/${resource}/${params.id}/order/`, {
                method: "DELETE"
            });
        } else {
            await httpClient(`${API_URL}/${resource}/${params.id}`, {
                method: "DELETE"
            });
        }
        return { data: params };
    }
}

export default TreeDataProvider;
