import { Show } from "react-admin";
import WTreeShowable from "./WTreeShowable";

const WTreeShow = () => {
    return (
        <Show>
            <WTreeShowable />
        </Show>
    );
};

export default WTreeShow;
