import { Edit } from "react-admin";
import WSpecieEditable from "./WSpecieEditable";

const WSpecieEdit = () => (
    <Edit>
        <WSpecieEditable />
    </Edit>
);

export default WSpecieEdit;
