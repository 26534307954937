import { Create } from "react-admin";
import WLocationEditable from "./WLocationEditable";

const WLocationCreate = () => (
    <Create>
        <WLocationEditable />
    </Create>
);

export default WLocationCreate;
