import { Edit } from "react-admin";
import WInitiativeEditable from "./WInitiativeEditable";

const WInitiativeEdit = () => (
    <Edit>
        <WInitiativeEditable />
    </Edit>
);

export default WInitiativeEdit;
