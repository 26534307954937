import {
    TextInput,
    TabbedForm,
    FormTab,
    TranslatableInputs,
    NumberInput,
    ArrayField,
    Datagrid,
    TextField,
    useRecordContext,
    useLocaleState,
    Labeled,
    useUpdate,
    useDelete,
    useNotify,
    useRefresh,
    FileInput,
    required,
    useTranslate
} from "react-admin";
import { Grid, ImageList, ImageListItem, ImageListItemBar, IconButton, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { supportedLocales } from "../../../../data/providers/i18n";
import { RichTextInput } from "ra-input-rich-text";
import { ORIGIN } from "../../../../data/common.js";
import noImage from "../../../../assets/images/no_tree_photo.svg";
import React from "react";

const WSpecieEditable = () => {
    const record = useRecordContext();
    const [locale] = useLocaleState();

    const notify = useNotify();
    const refresh = useRefresh();
    const [update] = useUpdate();
    const [deleteOne] = useDelete();

    const translate = useTranslate();

    const onFileChange = async file => {
        if (file && record?.id) {
            await update(
                "specie",
                {
                    id: record?.id,
                    meta: { file: true },
                    data: { image: file }
                },
                {
                    onSuccess: () => {
                        refresh();
                        notify("ra.notification.updated", {
                            messageArgs: { smart_count: 1 }
                        });
                    },
                    onError: error => {
                        if (error.status === 400) {
                            notify("other.errors.badRequest", {
                                type: "warning",
                                messageArgs: {
                                    reason: error.body?.detail ?? error.message
                                }
                            });
                        } else {
                            notify("other.errors.empty", {
                                type: "warning",
                                messageArgs: {
                                    reason: error.message
                                }
                            });
                        }
                    }
                }
            );
        }
    };

    const onImageDelete = async id => {
        await deleteOne(
            "specie",
            {
                id: record?.id,
                meta: { imageId: id }
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("ra.notification.updated", {
                        messageArgs: { smart_count: 1 }
                    });
                },
                onError: error => {
                    if (error.status === 400) {
                        notify("other.errors.badRequest", {
                            type: "warning",
                            messageArgs: {
                                reason: error.body?.detail ?? error.message
                            }
                        });
                    } else {
                        notify("other.errors.empty", {
                            type: "warning",
                            messageArgs: {
                                reason: error.message
                            }
                        });
                    }
                }
            }
        );
    };

    return (
        <TabbedForm>
            <FormTab label="resources.specie.tabs.common">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        <NumberInput source="price" variant="outlined" required fullWidth />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TranslatableInputs locales={supportedLocales} groupKey="common_name">
                            <TextInput source="common_name" validate={[required()]} variant="outlined" fullWidth />
                        </TranslatableInputs>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TranslatableInputs locales={supportedLocales} groupKey="scientific_name">
                            <TextInput source="scientific_name" validate={[required()]} variant="outlined" fullWidth />
                        </TranslatableInputs>
                    </Grid>
                    <Grid item xs={12}>
                        <TranslatableInputs locales={supportedLocales} groupKey="description">
                            <RichTextInput source="description" variant="outlined" fullWidth />
                        </TranslatableInputs>
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label="resources.specie.tabs.images" disabled={!record?.id}>
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    {record?.images && (
                        <Grid item xs={12}>
                            {record?.images?.length > 0 ? (
                                <Labeled source="images">
                                    <ImageList variant="masonry" cols={2} gap={8}>
                                        {record.images.map(image => (
                                            <ImageListItem key={image.id}>
                                                <img
                                                    src={new URL(image.path, ORIGIN).toString()}
                                                    alt=""
                                                    loading="lazy"
                                                    onError={e => (e.target.src = noImage)}
                                                />
                                                <ImageListItemBar
                                                    title={image.id}
                                                    subtitle={image.path}
                                                    actionIcon={
                                                        <IconButton
                                                            variant="outlined"
                                                            size="small"
                                                            color="error"
                                                            onClick={() => onImageDelete(image.id)}>
                                                            <Delete />
                                                        </IconButton>
                                                    }
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                </Labeled>
                            ) : (
                                <Labeled source="locations">
                                    <Typography variant="subtitle1">
                                        {translate("resources.specie.other.noImages")}
                                    </Typography>
                                </Labeled>
                            )}
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FileInput
                            source="uploadFile"
                            onChange={onFileChange}
                            label="resources.specie.fields.uploadImage"
                            accept="image/jpg, image/jpeg, image/png"
                        />
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label="resources.specie.tabs.locations" disabled={!record?.id}>
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        {record?.locations?.length > 0 ? (
                            <Labeled source="locations" fullWidth>
                                <ArrayField source="locations">
                                    <Datagrid
                                        bulkActionButtons={false}
                                        sx={{
                                            "& .RaDatagrid-headerCell": {
                                                fontWeight: "bold"
                                            }
                                        }}>
                                        <TextField source="id" />
                                        <TextField label="resources.location.fields.title" source={`title.${locale}`} />
                                    </Datagrid>
                                </ArrayField>
                            </Labeled>
                        ) : (
                            <Labeled source="locations">
                                <Typography variant="subtitle1">
                                    {translate("resources.specie.other.noLocations")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                </Grid>
            </FormTab>
        </TabbedForm>
    );
};

export default WSpecieEditable;
