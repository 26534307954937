import { Datagrid, TextField, List, useLocaleState, FilterLiveSearch, TopToolbar, CreateButton } from "react-admin";
import { Card, CardContent, Box } from "@mui/material";

const FilterSidebar = ({ withoutToolbar }) => (
    <Box
        sx={{
            display: {
                xs: "none",
                sm: "block"
            },
            order: -1,
            width: "15em",
            marginRight: "1em",
            marginTop: withoutToolbar ? "0" : "76px"
        }}>
        <Card>
            <CardContent>
                <FilterLiveSearch source="common_name" variant="outlined" label="resources.specie.fields.common_name" />
                <FilterLiveSearch
                    source="scientific_name"
                    variant="outlined"
                    label="resources.specie.fields.scientific_name"
                />
            </CardContent>
        </Card>
    </Box>
);

const SpecieActions = () => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

const WSpecieList = ({ withoutToolbar = false, children }) => {
    const [locale] = useLocaleState();
    return (
        <List
            exporter={false}
            aside={<FilterSidebar withoutToolbar={withoutToolbar} />}
            actions={!withoutToolbar && <SpecieActions />}>
            <Datagrid
                bulkActionButtons={false}
                sx={{
                    "& .RaDatagrid-headerCell": {
                        fontWeight: "bold"
                    }
                }}
                rowClick="show">
                <TextField source="id" />
                <TextField source={`common_name.${locale}`} />
                <TextField source={`scientific_name.${locale}`} />
                <TextField source="price" />
                {children}
            </Datagrid>
        </List>
    );
};

export default WSpecieList;
