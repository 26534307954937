import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "./styles/style.scss";
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxGl from "mapbox-gl";

mapboxGl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

let DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [24, 40],
    iconAnchor: [12, 40],
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);
