import { Show } from "react-admin";
import WSpecieShowable from "./WSpecieShowable";

const WSpecieShow = () => {
    return (
        <Show>
            <WSpecieShowable />
        </Show>
    );
};

export default WSpecieShow;
