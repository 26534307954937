import { Show } from "react-admin";

import WInitiativeShowable from "./WInitiativeShowable";

const WInitiativeShow = () => (
    <Show>
        <WInitiativeShowable />
    </Show>
);

export default WInitiativeShow;
