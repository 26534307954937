import { useRecordContext } from "react-admin";
import { get } from "lodash";
import { useEffect, useRef, useState } from "react";

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import beautify from "ace-builds/src-noconflict/ext-beautify";

const UIJsonField = ({ source }) => {
    const editorRef = useRef();
    const record = useRecordContext();
    const [value, setValue] = useState();

    useEffect(() => {
        beautify.beautify(editorRef.current?.editor?.session);
    });

    useEffect(() => {
        const tmp = JSON.stringify(typeof record === "object" ? get(record, source) : {})?.replace(/\\"/g, '"');
        if (tmp?.[0] === '"') {
            setValue(tmp.substring(1, tmp.length - 1));
        } else {
            setValue(tmp);
        }
    }, [record, source]);

    return (
        <AceEditor
            ref={editorRef}
            readOnly
            width="100%"
            maxLines={20}
            mode="json"
            theme="github"
            name="json-input"
            setOptions={{ useWorker: false }}
            value={value}
            editorProps={{ $blockScrolling: true }}
            commands={beautify.commands}
        />
    );
};

export default UIJsonField;
