import polyglotI18nProvider from "ra-i18n-polyglot";
import raEN from "ra-language-english";
// import ArabicMessages from "ra-language-arabic";

const en = {
    ...raEN,
    error: "%{error}",
    resources: {
        account: {
            name: "Account |||| Accounts",
            fields: {
                id: "ID",
                username: "User name",
                email: "Email",
                phone: "Phone number",
                role: "Role",
                firstname: "First name",
                lastname: "Last name",
                organization: "Organization",
                confirmed: "Account confirmed",
                phone_confirmed: "Phone confirmed",
                email_updates: "Email updates",
                visible: "Account visible",
                total_trees: "Total trees",
                total_orders: "Total orders",
                password: "Password",
                confirmPassword: "Confirm password",
                uploadImage: "Upload profile image"
            },
            roles: {
                root: "Root",
                admin: "Administrator",
                planter: "Planter",
                user: "User"
            },
            errors: {
                passwordNotMatch: "Passwords doesn't matches"
            }
        },
        specie: {
            name: "Specie |||| Species",
            fields: {
                id: "ID",
                common_name: "Common name",
                scientific_name: "Scientific name",
                "common_name.en": "Common name",
                "scientific_name.en": "Scientific name",
                description: "Description",
                price: "Price, ﷼",
                images: "Specie images",
                locations: "Specie locations",
                uploadImage: "Upload specie image"
            },
            tabs: {
                common: "Common",
                images: "Images",
                locations: "Locations"
            },
            other: {
                noImages: "No specie images",
                noLocations: "This specie doesn't linked to any location"
            }
        },
        location: {
            name: "Location |||| Locations",
            fields: {
                id: "ID",
                title: "Title",
                "title.en": "Title",
                country: "Country",
                city: "City",
                "country.en": "Country",
                "city.en": "City",
                uploadImage: "Upload location image"
            },
            tabs: {
                common: "Common",
                images: "Images",
                geolocation: "Geolocation",
                species: "Species"
            },
            other: {
                noSpecies: "No species linked to this location",
                linkedSpecies: "Linked species",
                addSpecie: "Select specie to link",
                noImages: "No location images"
            }
        },
        initiative: {
            name: "Initiative |||| Initiatives",
            fields: {
                id: "ID",
                title: "Title",
                begin_date: "Begin date",
                end_date: "End date",
                description: "Description",
                price_extra: "Extra price",
                target: "Target",
                uploadImage: "Upload initiative image"
            },
            tabs: {
                common: "Common",
                images: "Images",
                locations: "Locations",
                species: "Species"
            },
            other: {
                noSpecies: "No species linked to any location of this initiative",
                noLocations: "No locations linked to this initiative",
                linkedLocations: "Linked locations",
                species: "Species of initiative's locations",
                addLocation: "Select location to link",
                noImages: "No initiative images"
            }
        },
        order: {
            name: "Order |||| Orders",
            fields: {
                id: "ID",
                created_by: "Created by",
                owned_by: "Owned by",
                location: "Location",
                specie: "Specie"
            },
            tabs: {
                common: "Common",
                users: "Users",
                specie: "Specie",
                location: "Location",
                initiative: "Initiative"
            },
            other: {
                noAccount: "No account",
                noLocation: "No location",
                noSpecie: "No specie",
                noEmail: "No email",
                noPhone: "No phone",
                noInitiative: "No initiative",
                addUser: "Add user",
                addLocation: "Add location",
                addSpecie: "Add specie",
                addInitiative: "Add initiative",
                setOwner: "Set owner",
                setCreator: "Set creator"
            }
        },
        feedback: {
            name: "Feedback |||| Feedbacks",
            fields: {
                id: "ID",
                created_at: "Created at",
                name: "Name",
                email: "Email",
                text: "Text",
                resolved: "Resolved"
            }
        },
        tree: {
            name: "Tree |||| Trees",
            fields: {
                id: "ID",
                order_id: "Order ID",
                given_name: "Given name",
                trunk_diameter: "Trunk diameter",
                tree_height: "Tree height",
                canopy_height: "Canopy height",
                planted_date: "Planted date",
                hidden: "Hidden",
                owner: "Owner",
                planter: "Planter",
                creator: "Creator",
                location: "Location",
                specie: "Specie",
                recognized_specie: "Recognized specie",
                visibility: "Visibility",
                uploadImage: "Upload tree image",
                min_planted_date: "Min planted date",
                max_planted_date: "Max planted date",
                min_trunk_diameter: "Min trunk diameter",
                max_trunk_diameter: "Max trunk diameter",
                min_tree_height: "Min tree height",
                max_tree_height: "Max tree height"
            },
            tabs: {
                common: "Common",
                geolocation: "Geolocation",
                images: "Images",
                users: "Users",
                location: "Location",
                species: "Species",
                order: "Order"
            },
            other: {
                noAccount: "No account",
                noLocation: "No location",
                noSpecie: "No specie",
                noOrder: "No order",
                noImages: "No tree images",
                visibility: {
                    all: "Show all",
                    onlyVisible: "Only visible on map"
                },
                setPlanter: "Set planter",
                setRecognizedSpecie: "Set recognized specie",
                selectSpecie: "Select specie",
                matchOrder: "Match to tree",
                setOrder: "Set order",
                autoMatch: "Auto match"
            }
        },
        log: {
            fields: {
                begin_date: "Begin date",
                end_date: "End date"
            },
            deleted: "Log records from %{begin_date} to %{end_date} has been deleted"
        }
    },
    other: {
        errors: {
            empty: "%{reason}",
            badRequest: "Bad request: %{reason}"
        }
    }
};

const ar = {
    error: "%{error}",
    ra: {
        action: {
            add_filter: "إضافة فلتر",
            add: "إضافة",
            back: "العودة",
            bulk_actions: "%{smart_count} العناصر المحددة",
            cancel: "إلغاء",
            clear_input_value: "إفراغ المدخلات",
            clone: "استنساخ",
            confirm: "تأكيد",
            create: "إنشاء",
            delete: "حذف",
            edit: "تعديل",
            export: "تصدير",
            list: "قائمة",
            refresh: "إعادة تحميل",
            remove_filter: "إزالة هذا الفلتر",
            remove: "إزالة",
            save: "حفظ",
            search: "بحث",
            show: "عرض التفاصيل",
            sort: "فرز",
            undo: "تراجع",
            unselect: "الغاء التحديد",
            expand: "فرد",
            close: "اغلاق",
            open_menu: "افتح القائمة",
            close_menu: "اغلق القائمة"
        },
        boolean: {
            true: "نعم",
            false: "لا",
            null: ""
        },
        page: {
            create: "إنشاء %{name}",
            dashboard: "لوحة الإحصائيات",
            edit: "%{name} #%{id}",
            error: "هناك خطأ ما",
            list: "%{name}",
            loading: "جار التحميل",
            not_found: "غير موجود",
            show: "%{name} #%{id}",
            empty: "لا يوجد %{name} حتي الان",
            invite: "هل ترغب فى اضافة واحد؟"
        },
        input: {
            file: {
                upload_several: "إسقاط بعض الملفات للتحميل، أو انقر لتحديد واحد.",
                upload_single: "إسقاط ملف للتحميل، أو انقر لتحديده."
            },
            image: {
                upload_several: "قم بإسقاط بعض الصور للتحميل، أو انقر لتحديد واحدة.",
                upload_single: "إسقاط صورة للتحميل، أو انقر لتحديدها."
            },
            references: {
                all_missing: "غير قادر على العثور على بيانات المراجع.",
                many_missing: "واحد على الأقل من المراجع المرتبطة لم تعد متوفرة.",
                single_missing: "المرجع المرتبط لم يعد يبدو متاحًا."
            },
            password: {
                toggle_visible: "اخفاء الرقم السري",
                toggle_hidden: "اظهار الرقم السري"
            }
        },
        message: {
            about: "حول",
            are_you_sure: "هل أنت واثق؟",
            bulk_delete_content:
                "هل أنت متأكد أنك تريد حذف هذا %{name}? |||| هل أنت متأكد من أنك تريد حذف هذه العناصر%{smart_count}?",
            bulk_delete_title: "حذف %{name} |||| احذف عناصر%{smart_count}%{name}",
            delete_content: "هل أنت متأكد أنك تريد حذف هذا البند؟",
            delete_title: "حذف %{name} #%{id}",
            details: "تفاصيل",
            error: "حدث خطأ في التطبيق ولم يمكن إكمال طلبك.",
            invalid_form: "النموذج غير صالح. يرجى التحقق من وجود أخطاء",
            loading: "يتم تحميل الصفحة، فقط لحظة من فضلك",
            no: "لا",
            not_found: "الصفحة غير موجودة",
            yes: "نعم ",
            unsaved_changes: "لم يتم حفظ بعض تغييراتك. هل أنت متأكد أنك تريد تجاهلها؟"
        },
        navigation: {
            no_results: "لا توجد نتائج",
            no_more_results: "رقم الصفحة%{page} خارج الحدود. جرب الصفحة السابقة.",
            page_out_of_boundaries: "رقم الصفحة%{page} خارج الحدود",
            page_out_from_end: "لا يمكن الذهاب بعد الصفحة الأخيرة",
            page_out_from_begin: "لا يمكن الذهاب قبل الصفحة الأولى",
            page_range_info: "%{offsetBegin}-%{offsetEnd} من %{total}",
            page_rows_per_page: "الصفوف لكل صفحة:",
            next: "التالى",
            prev: "السابق",
            skip_nav: "تخطي الى المحتوي"
        },
        sort: {
            sort_by: "رتب حسب %{field} %{order}",
            ASC: "تصاعدي",
            DESC: "تنازلي"
        },
        auth: {
            auth_check_error: "الرجاء تسجيل الدخول للاستمرار",
            user_menu: "الملف الشخصي",
            username: "اسم المستخدم",
            password: "كلمة السر",
            sign_in: "تسجيل الدخول",
            sign_in_error: "أخفقت المصادقة، يرجى إعادة المحاولة",
            logout: "الخروج"
        },
        notification: {
            updated: "تم تحديث العنصر |||| تم تحديث%{smart_count} من العناصر",
            created: "تم إنشاء العنصر",
            deleted: "تم حذف العنصر |||| تم حذف%{smart_count} من العناصر",
            bad_item: "عنصر غير صحيح",
            item_doesnt_exist: "العنصر غير موجود",
            http_error: "خطأ في اتصال الخادم",
            i18n_error: "لا يمكن تحميل الترجمة لهذه اللغة",
            data_provider_error: "خطأ في مزود البيانات. تحقق من وحدة التحكم للحصول على التفاصيل.",
            canceled: "تم إلغاء الإجراء",
            logged_out: "انتهت جلستك، يرجى إعادة الاتصال."
        },
        validation: {
            required: "مطلوب",
            minLength: "يجب أن يكون%{min} حرفًا على الأقل",
            maxLength: "يجب أن يكون%{max} حرفًا أو أقل",
            minValue: "يجب أن يكون%{min} على الأقل",
            maxValue: "يجب أن يكون%{max} أو أقل",
            number: "يجب أن يكون رقما",
            email: "يجب أن يكون بريدًا إلكترونيًا صالحًا",
            oneOf: "يجب أن يكون واحدًا من:%{options}",
            regex: "يجب أن يتطابق مع تنسيق محدد (regex):%{pattern}"
        }
    },
    resources: {
        account: {
            name: "الحساب |||| حسابات",
            fields: {
                id: "بطاقة تعريف",
                username: "اسم المستخدم",
                email: "البريد الإلكتروني",
                phone: "رقم الهاتف",
                role: "دور",
                firstname: "الاسم الاول",
                lastname: "اللقب",
                organization: "منظمة",
                confirmed: "تم تأكيد الحساب",
                phone_confirmed: "تم تأكيد الهاتف",
                email_updates: "تحديثات البريد الإلكتروني",
                visible: "الحساب مرئي",
                total_trees: "مجموع الأشجار",
                total_orders: "إجمالي الطلبات",
                password: "كلمة المرور",
                confirmPassword: "تأكيد كلمة المرور",
                uploadImage: "تحميل صورة الملف الشخصي"
            },
            roles: {
                root: "جذر",
                admin: "مدير",
                planter: "زارع",
                user: "المستعمل"
            },
            errors: {
                passwordNotMatch: "كلمات المرور لا تتطابق"
            }
        },
        specie: {
            name: "نوع |||| محيط",
            fields: {
                id: "بطاقة تعريف",
                common_name: "اسم شائع",
                scientific_name: "الاسم العلمي",
                "common_name.ar": "اسم شائع",
                "scientific_name.ar": "الاسم العلمي",
                description: "وصف",
                price: "سعر, ﷼",
                images: "الصور الخاصة",
                locations: "مواقع محددة",
                uploadImage: "تحميل صورة مسكوكة"
            },
            tabs: {
                common: "مشترك",
                images: "الصور",
                locations: "المواقع"
            },
            other: {
                noImages: "لا توجد صور مسكوكة",
                noLocations: "هذا النوع لا يرتبط بأي مكان"
            }
        },
        location: {
            name: "موقع |||| المواقع",
            fields: {
                id: "بطاقة تعريف",
                title: "عنوان",
                "title.ar": "عنوان",
                country: "دولة",
                city: "مدينة",
                "country.ar": "دولة",
                "city.ar": "مدينة",
                uploadImage: "تحميل صورة الموقع"
            },
            tabs: {
                common: "مشترك",
                images: "الصور",
                geolocation: "تحديد الموقع الجغرافي",
                species: "محيط"
            },
            other: {
                noSpecies: "لا توجد أنواع مرتبطة بهذا الموقع",
                linkedSpecies: "الأنواع المرتبطة",
                addSpecie: "حدد محدد للربط",
                noImages: "لا توجد صور الموقع"
            }
        },
        initiative: {
            name: "مبادرة |||| المبادرات",
            fields: {
                id: "بطاقة تعريف",
                title: "عنوان",
                begin_date: "موعد البدء",
                end_date: "تاريخ الانتهاء",
                description: "وصف",
                price_extra: "سعر إضافي",
                target: "استهداف",
                uploadImage: "تحميل صورة المبادرة"
            },
            tabs: {
                common: "مشترك",
                images: "الصور",
                locations: "المواقع",
                species: "محيط"
            },
            other: {
                noSpecies: "لا توجد أنواع مرتبطة بأي موقع لهذه المبادرة",
                noLocations: "لا توجد مواقع مرتبطة بهذه المبادرة",
                linkedLocations: "المواقع المرتبطة",
                species: "أنواع مواقع المبادرة",
                addLocation: "حدد الموقع للربط",
                noImages: "لا توجد صور للمبادرة"
            }
        },
        order: {
            name: "الطلب |||| الطلب",
            fields: {
                id: "بطاقة تعريف",
                created_by: "انشأ من قبل",
                owned_by: "مملوكة من قبل",
                location: "موقع",
                specie: "نوع"
            },
            tabs: {
                common: "مشترك",
                users: "المستخدمون",
                specie: "نوع",
                location: "موقع",
                initiative: "مبادرة"
            },
            other: {
                noAccount: "لا حساب",
                noLocation: "لا يوجد موقع",
                noSpecie: "لا مسكوكة",
                noEmail: "لا البريد الإلكتروني",
                noPhone: "لا يوجد هاتف",
                noInitiative: "لا مبادرة",
                addUser: "إضافة مستخدم",
                addLocation: "أضف الموقع",
                addSpecie: "إضافة مسكوكة",
                addInitiative: "أضف مبادرة",
                setOwner: "مجموعة المالك",
                setCreator: "تعيين الخالق"
            }
        },
        feedback: {
            name: "استجابة |||| التقيمات",
            fields: {
                id: "بطاقة تعريف",
                created_at: "أنشئت فيt",
                name: "اسم",
                email: "البريد الإلكتروني",
                text: "نص",
                resolved: "تم الحل"
            }
        },
        tree: {
            name: "شجرة |||| الأشجار",
            fields: {
                id: "بطاقة تعريف",
                order_id: "رقم التعريف الخاص بالطلب",
                given_name: "الاسم المعطى",
                trunk_diameter: "قطر الجذع",
                tree_height: "ارتفاع الشجرة",
                canopy_height: "ارتفاع المظلة",
                planted_date: "تاريخ المزروعة",
                hidden: "مختفي",
                owner: "صاحب",
                planter: "زارع",
                creator: "المنشئ",
                location: "موقع",
                specie: "نوع",
                recognized_specie: "مسكوكة معترف بها",
                visibility: "الرؤية",
                uploadImage: "تحميل صورة شجرة",
                min_planted_date: "تاريخ زرع دقيقة",
                max_planted_date: "تاريخ زرع ماكس",
                min_trunk_diameter: "قطر الجذع الأدنى",
                max_trunk_diameter: "أقصى قطر للجذع",
                min_tree_height: "ارتفاع الشجرة الأدنى",
                max_tree_height: "أقصى ارتفاع للشجرة"
            },
            tabs: {
                common: "مشترك",
                geolocation: "تحديد الموقع الجغرافي",
                images: "الصور",
                users: "المستخدمون",
                location: "موقع",
                species: "محيط",
                order: "ترتيب"
            },
            other: {
                noAccount: "لا حساب",
                noLocation: "لا يوجد موقع",
                noSpecie: "لا مسكوكة",
                noOrder: "لا طلب",
                noImages: "لا توجد صور شجرة",
                visibility: {
                    all: "عرض الكل",
                    onlyVisible: "مرئي فقط على الخريطة"
                },
                setPlanter: "وضع الغراس",
                setRecognizedSpecie: "تعيين مسكوكة معترف بها",
                selectSpecie: "حدد مسكوكة",
                matchOrder: "تطابق الشجرة",
                setOrder: "سن أمرا",
                autoMatch: "تطابق تلقائي"
            }
        },
        log: {
            fields: {
                begin_date: "موعد البدء",
                end_date: "تاريخ الانتهاء"
            },
            deleted: "تم حذف سجلات السجل من %{begin_date} إلى %{end_date}"
        }
    },
    other: {
        errors: {
            empty: "%{reason}",
            badRequest: "اقتراح غير جيد: %{reason}"
        }
    }
};

const translations = { en, ar };

const i18nProvider = polyglotI18nProvider(locale => translations[locale], "en");

export const supportedLocales = ["en", "ar"];

export default i18nProvider;
