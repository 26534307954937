import {
    TextInput,
    TabbedForm,
    FormTab,
    TranslatableInputs,
    NumberInput,
    useUpdate,
    useDelete,
    useNotify,
    useRefresh,
    ArrayField,
    Datagrid,
    TextField,
    useRecordContext,
    Labeled,
    FileInput,
    ReferenceArrayInput,
    useLocaleState,
    useTranslate,
    FunctionField,
    Button,
    required
} from "react-admin";
import { Grid, ImageList, ImageListItem, ImageListItemBar, IconButton, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { RichTextInput } from "ra-input-rich-text";
import { supportedLocales } from "../../../../data/providers/i18n";
import { ORIGIN } from "../../../../data/common";
import noImage from "../../../../assets/images/no_tree_photo.svg";
import WLocationList from "../../lists/WLocationList";
import UIDateInput from "../../../ui/inputs/UIDateInput";

const WInitiativeEditable = () => {
    const record = useRecordContext();
    const [locale] = useLocaleState();
    const translate = useTranslate();

    const notify = useNotify();
    const refresh = useRefresh();
    const [update] = useUpdate();
    const [deleteOne] = useDelete();

    const onFileChange = async file => {
        if (file && record?.id) {
            await update(
                "initiative",
                {
                    id: record?.id,
                    meta: { file: true },
                    data: { image: file }
                },
                {
                    onSuccess: () => {
                        refresh();
                        notify("ra.notification.updated", {
                            messageArgs: { smart_count: 1 }
                        });
                    },
                    onError: error => {
                        if (error.status === 400) {
                            notify("other.errors.badRequest", {
                                type: "warning",
                                messageArgs: {
                                    reason: error.body?.detail ?? error.message
                                }
                            });
                        } else {
                            notify("other.errors.empty", {
                                type: "warning",
                                messageArgs: {
                                    reason: error.message
                                }
                            });
                        }
                    }
                }
            );
        }
    };

    const onImageDelete = async id => {
        await deleteOne(
            "initiative",
            {
                id: record?.id,
                meta: { imageId: id }
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("ra.notification.updated", {
                        messageArgs: { smart_count: 1 }
                    });
                },
                onError: error => {
                    if (error.status === 400) {
                        notify("other.errors.badRequest", {
                            type: "warning",
                            messageArgs: {
                                reason: error.body?.detail ?? error.message
                            }
                        });
                    } else {
                        notify("other.errors.empty", {
                            type: "warning",
                            messageArgs: {
                                reason: error.message
                            }
                        });
                    }
                }
            }
        );
    };

    const onLocationSelected = async location => {
        if (location && record?.id) {
            await update(
                "initiative",
                {
                    id: record?.id,
                    meta: { location: true },
                    data: { location: location }
                },
                {
                    onSuccess: () => {
                        refresh();
                        notify("ra.notification.updated", {
                            messageArgs: { smart_count: 1 }
                        });
                    },
                    onError: error => {
                        if (error.status === 400) {
                            notify("other.errors.badRequest", {
                                type: "warning",
                                messageArgs: {
                                    reason: error.body?.detail ?? error.message
                                }
                            });
                        } else {
                            notify("other.errors.empty", {
                                type: "warning",
                                messageArgs: {
                                    reason: error.message
                                }
                            });
                        }
                    }
                }
            );
        }
    };

    const onLocationSelectedToDelete = async location => {
        await deleteOne(
            "initiative",
            {
                id: record?.id,
                meta: { locationId: location.id }
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("ra.notification.updated", {
                        messageArgs: { smart_count: 1 }
                    });
                },
                onError: error => {
                    if (error.status === 400) {
                        notify("other.errors.badRequest", {
                            type: "warning",
                            messageArgs: {
                                reason: error.body?.detail ?? error.message
                            }
                        });
                    } else {
                        notify("other.errors.empty", {
                            type: "warning",
                            messageArgs: {
                                reason: error.message
                            }
                        });
                    }
                }
            }
        );
    };

    return (
        <TabbedForm syncWithLocation={false}>
            <FormTab label="resources.initiative.tabs.common">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={6}>
                        <UIDateInput
                            showTime
                            source="begin_date"
                            fullWidth
                            variant="outlined"
                            validate={[required()]}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <UIDateInput showTime source="end_date" fullWidth variant="outlined" validate={[required()]} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TranslatableInputs locales={supportedLocales} groupKey="title">
                            <TextInput source="title" fullWidth validate={[required()]} variant="outlined" />
                        </TranslatableInputs>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TranslatableInputs locales={supportedLocales} groupKey="short_description">
                            <TextInput
                                source="short_description"
                                fullWidth
                                validate={[required()]}
                                variant="outlined"
                            />
                        </TranslatableInputs>
                    </Grid>
                    <Grid item xs={12}>
                        <TranslatableInputs locales={supportedLocales} groupKey="description">
                            <RichTextInput validate={[required()]} source="description" fullWidth variant="outlined" />
                        </TranslatableInputs>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <NumberInput source="price_extra" variant="outlined" fullWidth validate={[required()]} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <NumberInput source="target" variant="outlined" fullWidth validate={[required()]} />
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label="resources.initiative.tabs.images" disabled={!record?.id}>
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid container columnSpacing={2} sx={{ p: 2 }}>
                        {record?.images && (
                            <Grid item xs={12}>
                                <Labeled source="images">
                                    <ImageList variant="masonry" cols={2} gap={8}>
                                        {record.images.map(image => (
                                            <ImageListItem key={image.id}>
                                                <img
                                                    src={new URL(image.path, ORIGIN).toString()}
                                                    alt=""
                                                    loading="lazy"
                                                    onError={e => (e.target.src = noImage)}
                                                />
                                                <ImageListItemBar
                                                    title={image.id}
                                                    subtitle={image.path}
                                                    actionIcon={
                                                        <IconButton
                                                            variant="outlined"
                                                            size="small"
                                                            color="error"
                                                            onClick={() => onImageDelete(image.id)}>
                                                            <Delete />
                                                        </IconButton>
                                                    }
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                </Labeled>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <FileInput
                                source="uploadFile"
                                onChange={onFileChange}
                                label="resources.initiative.fields.uploadImage"
                                accept="image/jpg, image/jpeg, image/png"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label="resources.initiative.tabs.locations" disabled={!record?.id}>
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        <Labeled label="resources.initiative.other.linkedLocations" fullWidth>
                            {record?.locations?.length > 0 ? (
                                <ArrayField source="locations">
                                    <Datagrid
                                        bulkActionButtons={false}
                                        sx={{
                                            "& .RaDatagrid-headerCell": {
                                                fontWeight: "bold"
                                            }
                                        }}
                                        rowClick={id => `/location/${id}/show`}>
                                        <TextField source="id" />
                                        <TextField source={`title.${locale}`} />
                                        <TextField source={`country.${locale}`} />
                                        <TextField source={`city.${locale}`} />
                                        <FunctionField
                                            render={r => (
                                                <Button
                                                    startIcon={<Delete />}
                                                    label="ra.action.delete"
                                                    color="error"
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        onLocationSelectedToDelete?.call(null, r);
                                                    }}
                                                />
                                            )}
                                        />
                                    </Datagrid>
                                </ArrayField>
                            ) : (
                                <Typography variant="subtitle1">
                                    {translate("resources.initiative.other.noLocations")}
                                </Typography>
                            )}
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled fullWidth label="resources.initiative.other.addLocation" sx={{ marginTop: "2em" }}>
                            <ReferenceArrayInput source="location_ids" reference="location">
                                <WLocationList withoutToolbar onLocationSelectButtonClick={onLocationSelected} />
                            </ReferenceArrayInput>
                        </Labeled>
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label="resources.initiative.tabs.species" disabled={!record?.id}>
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        {record?.species?.length > 0 ? (
                            <Labeled label="resources.initiative.other.species" fullWidth>
                                <ArrayField source="species">
                                    <Datagrid
                                        bulkActionButtons={false}
                                        sx={{
                                            "& .RaDatagrid-headerCell": {
                                                fontWeight: "bold"
                                            }
                                        }}
                                        rowClick={id => `/specie/${id}/show`}>
                                        <TextField source="id" />
                                        <TextField source={`common_name.${locale}`} />
                                        <TextField source={`scientific_name.${locale}`} />
                                        <TextField source="price" />
                                    </Datagrid>
                                </ArrayField>
                            </Labeled>
                        ) : (
                            <Labeled label="resources.initiative.other.species">
                                <Typography variant="subtitle1">
                                    {translate("resources.initiative.other.noSpecies")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                </Grid>
            </FormTab>
        </TabbedForm>
    );
};

export default WInitiativeEditable;
