import { API_URL } from "../common";
import httpClient from "../../services/http";
import BaseDataProvider from "./base";

class SpecieDataProvider extends BaseDataProvider {
    async getList(resource, params) {
        const { filter, sort } = params;
        const { page, perPage } = params.pagination;
        const url = new URL(`${API_URL}/${resource}`);
        url.search = new URLSearchParams({
            ...filter,
            sort_direction: sort?.order,
            sort_by: sort?.field?.split(".").shift()
        })?.toString();
        const { json } = await httpClient(url, {
            headers: new Headers({
                "x-offset": (page - 1) * perPage,
                "x-count": perPage
            })
        });
        return {
            data: json.species,
            total: json.total_count
        };
    }

    async update(resource, params) {
        const { image, ...payload } = params.data;
        if (params.meta?.file && image) {
            const form = new FormData();
            form.append("image", image);
            const { json } = await httpClient(`${API_URL}/${resource}/${params.id}/image`, {
                method: "POST",
                body: form
            });
            return { data: json };
        } else {
            await httpClient(`${API_URL}/${resource}/${params.id}`, {
                method: "PUT",
                body: JSON.stringify(payload)
            });
            return { data: payload };
        }
    }

    async delete(resource, params) {
        if (params.meta?.imageId) {
            await httpClient(`${API_URL}/${resource}/${params.id}/image/${params.meta?.imageId}`, {
                method: "DELETE"
            });
        } else {
            await httpClient(`${API_URL}/${resource}/${params.id}`, {
                method: "DELETE"
            });
        }
        return { data: params };
    }
}

export default SpecieDataProvider;
