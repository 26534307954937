import { Create } from "react-admin";
import WSpecieEditable from "./WSpecieEditable";

const WSpecieCreate = () => (
    <Create>
        <WSpecieEditable />
    </Create>
);

export default WSpecieCreate;
