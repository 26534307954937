import { Create } from "react-admin";
import WOrderEditable from "./WOrderEditable";

const WOrderCreate = () => (
    <Create>
        <WOrderEditable />
    </Create>
);

export default WOrderCreate;
