import { Labeled, useInput } from "react-admin";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { useState } from "react";

const UIJsonInput = props => {
    const {
        field: { value, onChange }
    } = useInput(props);

    const { source, label } = props;

    const [jsonString, setJsonString] = useState(JSON.stringify(value ?? {}, null, 4));

    const onChanged = data => {
        setJsonString(data);
        try {
            onChange(JSON.parse(data));
        } catch {}
    };

    const onBlur = () => {
        setJsonString(JSON.stringify(value ?? {}, null, 4));
    };

    return (
        <Labeled fullWidth source={source} label={label}>
            <AceEditor
                width="100%"
                maxLines={20}
                mode="json"
                theme="github"
                onBlur={onBlur}
                onChange={onChanged}
                setOptions={{ useWorker: false }}
                name="json-input"
                value={jsonString}
                editorProps={{ $blockScrolling: true }}
            />
        </Labeled>
    );
};

export default UIJsonInput;
