import { fetchUtils } from "react-admin";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers();
    }
    const { token } = JSON.parse(localStorage.getItem("auth"));
    options.headers.set("Accept", "application/json");
    options.headers.set("Authorization", `Bearer ${token}`);
    // TODO use locale here
    // options.headers.set("Accept-Language", "en");
    options.headers.set("Accept-Language", "");
    return fetchUtils.fetchJson(url, options);
};

export default httpClient;
