import {
    Datagrid,
    TextField,
    List,
    FilterLiveSearch,
    FilterList,
    FilterListItem,
    TopToolbar,
    CreateButton
} from "react-admin";
import { Card, CardContent, Box } from "@mui/material";
import { SupervisorAccount } from "@mui/icons-material";

const RolesFilter = () => (
    <FilterList icon={<SupervisorAccount />} label="resources.account.fields.role">
        <FilterListItem label="resources.account.roles.admin" value={{ role: "admin" }} />
        <FilterListItem label="resources.account.roles.planter" value={{ role: "planter" }} />
        <FilterListItem label="resources.account.roles.user" value={{ role: "user" }} />
    </FilterList>
);

const FilterSidebar = ({ withoutToolbar }) => (
    <Box
        sx={{
            display: {
                xs: "none",
                sm: "block"
            },
            order: -1,
            width: "15em",
            marginRight: "1em",
            marginTop: withoutToolbar ? "0" : "76px"
        }}>
        <Card>
            <CardContent>
                <FilterLiveSearch source="username" variant="outlined" label="resources.account.fields.username" />
                <FilterLiveSearch source="email" variant="outlined" label="resources.account.fields.email" />
                <FilterLiveSearch source="firstname" variant="outlined" label="resources.account.fields.firstname" />
                <FilterLiveSearch source="lastname" variant="outlined" label="resources.account.fields.lastname" />
                <FilterLiveSearch
                    source="organization"
                    variant="outlined"
                    label="resources.account.fields.organization"
                />
                <RolesFilter />
            </CardContent>
        </Card>
    </Box>
);

const AccountActions = () => (
    <TopToolbar>
        <CreateButton />
    </TopToolbar>
);

const WAccountList = ({ withoutToolbar = false, children }) => (
    <List
        exporter={false}
        aside={<FilterSidebar withoutToolbar={withoutToolbar} />}
        actions={!withoutToolbar && <AccountActions />}>
        <Datagrid
            bulkActionButtons={false}
            sx={{
                "& .RaDatagrid-headerCell": {
                    fontWeight: "bold"
                }
            }}
            rowClick="show">
            <TextField source="id" />
            <TextField source="username" />
            <TextField source="firstname" />
            <TextField source="lastname" />
            <TextField source="organization" />
            <TextField source="role" sortable={false} />
            {children}
        </Datagrid>
    </List>
);

export default WAccountList;
