import { Button, Form, useDelete, useNotify, useRefresh, useTranslate, required } from "react-admin";
import { Fragment, useState } from "react";
import { Delete, Cancel } from "@mui/icons-material";
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import useOnFailureMessage from "../../hooks/use-on-failure-message";
import UIDateInput from "./inputs/UIDateInput";

const UIDeleteLogsButton = () => {
    const [open, setOpen] = useState(false);

    const notify = useNotify();
    const refresh = useRefresh();
    const translate = useTranslate();
    const onFailure = useOnFailureMessage();

    const onDeleteButtonClicked = () => {
        setOpen(true);
    };

    const onCancelButtonClicked = () => {
        setOpen(false);
    };

    const onDialogClosed = () => {
        setOpen(false);
    };

    const [deleteOne] = useDelete();

    const onFormSubmit = async data => {
        const payload = {
            begin_date: data.begin_date?.toISOString(),
            end_date: data.end_date?.toISOString()
        };
        await deleteOne(
            "log",
            {
                meta: payload
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("resources.log.deleted", {
                        messageArgs: payload
                    });
                },
                onError: onFailure
            }
        );
        setOpen(false);
    };

    return (
        <Fragment>
            <Button color="error" label="ra.action.delete" onClick={onDeleteButtonClicked}>
                <Delete />
            </Button>
            <Dialog fullWidth open={open} onClose={onDialogClosed}>
                <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography>{translate("ra.action.delete")}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Form id="delete-logs-form" resource="log" onSubmit={onFormSubmit}>
                        <UIDateInput
                            showTime
                            variant="outlined"
                            fullWidth
                            validate={[required()]}
                            source="begin_date"
                        />
                        <UIDateInput showTime variant="outlined" fullWidth validate={[required()]} source="end_date" />
                    </Form>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        p: 3
                    }}>
                    <Button label="ra.action.cancel" color="secondary" onClick={onCancelButtonClicked}>
                        <Cancel />
                    </Button>
                    <Button label="ra.action.delete" type="submit" color="error" form="delete-logs-form">
                        <Delete />
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default UIDeleteLogsButton;
