import {
    TextInput,
    SelectInput,
    PasswordInput,
    BooleanInput,
    SimpleForm,
    useRecordContext,
    FileInput,
    useNotify,
    useRefresh,
    useUpdate,
    useDelete,
    useTranslate,
    usePermissions
} from "react-admin";
import { Grid, Avatar, Badge, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { ORIGIN } from "../../../../data/common.js";

const EditableProfilePicture = () => {
    const record = useRecordContext();
    const translate = useTranslate();

    const notify = useNotify();
    const refresh = useRefresh();
    const [update] = useUpdate();
    const [deleteOne] = useDelete();

    const onFileChange = async file => {
        if (file && record?.id) {
            await update(
                "account",
                {
                    id: record?.id,
                    meta: { file: true },
                    data: { image: file }
                },
                {
                    onSuccess: () => {
                        refresh();
                        notify("ra.notification.updated", {
                            messageArgs: { smart_count: 1 }
                        });
                    },
                    onError: error => {
                        if (error.status === 400) {
                            notify("other.errors.badRequest", {
                                type: "warning",
                                messageArgs: {
                                    reason: error.body?.detail ?? error.message
                                }
                            });
                        } else {
                            notify("other.errors.empty", {
                                type: "warning",
                                messageArgs: {
                                    reason: error.message
                                }
                            });
                        }
                    }
                }
            );
        }
    };

    const onImageDelete = async () => {
        await deleteOne(
            "account",
            {
                id: record?.id,
                meta: { imageId: record?.image?.id }
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("ra.notification.updated", {
                        messageArgs: { smart_count: 1 }
                    });
                },
                onError: error => {
                    if (error.status === 400) {
                        notify("other.errors.badRequest", {
                            type: "warning",
                            messageArgs: {
                                reason: error.body?.detail ?? error.message
                            }
                        });
                    } else {
                        notify("other.errors.empty", {
                            type: "warning",
                            messageArgs: {
                                reason: error.message
                            }
                        });
                    }
                }
            }
        );
    };

    if (record) {
        return record.image ? (
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                    <IconButton variant="outlined" size="small" color="error" onClick={onImageDelete}>
                        <Delete />
                    </IconButton>
                }>
                <Avatar
                    alt={record?.username}
                    src={new URL(record?.image?.path, ORIGIN).toString()}
                    sx={{ width: 128, height: 128 }}
                />
            </Badge>
        ) : (
            <FileInput
                source="uploadFile"
                onChange={onFileChange}
                label={translate("resources.account.fields.uploadImage")}
                accept="image/jpg, image/jpeg, image/png"
            />
        );
    } else {
        return "";
    }
};

const passwordMatchValidation = (value, allValues) => {
    if (value !== allValues.password) {
        return "resources.account.errors.passwordNotMatch";
    }
    return undefined;
};

const WAccountEditable = () => {
    const { permissions: role } = usePermissions();
    const roles = [
        {
            id: "admin",
            name: "resources.account.roles.admin"
        },
        {
            id: "planter",
            name: "resources.account.roles.planter"
        },
        {
            id: "user",
            name: "resources.account.roles.user"
        }
    ];
    if (role === "root") {
        roles.push({
            id: "root",
            name: "resources.account.roles.root"
        });
    }
    return (
        <SimpleForm>
            <Grid container columnSpacing={2} sx={{ p: 2 }}>
                <Grid container item xs={12} justifyContent="center">
                    <EditableProfilePicture />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput source="username" variant="outlined" required fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput source="email" variant="outlined" required fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput source="phone" variant="outlined" required fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SelectInput source="role" choices={roles} required variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PasswordInput source="password" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PasswordInput
                        source="confirmPassword"
                        variant="outlined"
                        validate={[passwordMatchValidation]}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput source="firstname" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextInput source="lastname" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="organization" variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={12} md={6}>
                    <BooleanInput source="confirmed" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <BooleanInput source="phone_confirmed" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <BooleanInput source="email_updates" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <BooleanInput source="visible" />
                </Grid>
            </Grid>
        </SimpleForm>
    );
};

export default WAccountEditable;
