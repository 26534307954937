import { Create } from "react-admin";
import WInitiativeEditable from "./WInitiativeEditable";

const WInitiativeCreate = () => (
    <Create>
        <WInitiativeEditable />
    </Create>
);

export default WInitiativeCreate;
