import { Create } from "react-admin";
import WAccountEditable from "./WAccountEditable";

const WAccountCreate = () => (
    <Create>
        <WAccountEditable />
    </Create>
);

export default WAccountCreate;
