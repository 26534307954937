import { Show } from "react-admin";
import WLogShowable from "./WLogShowable";

const WLogShow = () => (
    <Show>
        <WLogShowable />
    </Show>
);

export default WLogShow;
