import {
    Datagrid,
    TextField,
    List,
    FunctionField,
    DateField,
    Link,
    useTranslate,
    useLocaleState,
    useListContext,
    TextInput
} from "react-admin";
import { Card, CardContent, Box, Typography, InputAdornment } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import UIDateInput from "../../ui/inputs/UIDateInput";
import { debounce } from "lodash";
import { Numbers, Title, Person, Spa, LocationOn, HideSource, Height } from "@mui/icons-material";

const DateFilter = () => {
    const { filterValues, setFilters } = useListContext();

    const form = useForm({
        defaultValues: filterValues
    });

    const onDatePropertySelected = debounce((value, type) => {
        if (value instanceof Date && !isNaN(value)) {
            setFilters({ ...filterValues, [type]: value });
        } else {
            Reflect.deleteProperty(filterValues, type);
            setFilters(filterValues);
        }
    }, 300);

    const onPropertySelected = debounce((value, type) => {
        if (value) {
            setFilters({ ...filterValues, [type]: value });
        } else {
            Reflect.deleteProperty(filterValues, type);
            setFilters(filterValues);
        }
    }, 300);

    const onIdSelected = e => onPropertySelected(e.target.value, "id");
    const onGivenNameSelected = e => onPropertySelected(e.target.value, "given_name");
    const onOwnerSelected = e => onPropertySelected(e.target.value, "owner");
    const onPlanterSelected = e => onPropertySelected(e.target.value, "planter");
    const onCreatorSelected = e => onPropertySelected(e.target.value, "creator");
    const onSpecieSelected = e => onPropertySelected(e.target.value, "specie");
    const onRecognizedSpecieSelected = e => onPropertySelected(e.target.value, "recognized_specie");
    const onLocationSelected = e => onPropertySelected(e.target.value, "location");
    const onMaxDateSelected = value => onDatePropertySelected(value, "max_planted_date");
    const onMinDateSelected = value => onDatePropertySelected(value, "min_planted_date");
    const onMinTrunkDiameterSelected = e => onPropertySelected(e.target.value, "min_trunk_diameter");
    const onMaxTrunkDiameterSelected = e => onPropertySelected(e.target.value, "max_trunk_diameter");
    const onMinTreeHeightSelected = e => onPropertySelected(e.target.value, "min_tree_height");
    const onMaxTreeHeightSelected = e => onPropertySelected(e.target.value, "max_tree_height");

    return (
        <FormProvider {...form}>
            <TextInput
                type="number"
                source="id"
                variant="outlined"
                onChange={onIdSelected}
                helperText={false}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Numbers />
                        </InputAdornment>
                    )
                }}
            />
            <TextInput
                source="given_name"
                sortBy="order.given_name"
                variant="outlined"
                onChange={onGivenNameSelected}
                helperText={false}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Title />
                        </InputAdornment>
                    )
                }}
            />
            <TextInput
                source="owner"
                variant="outlined"
                onChange={onOwnerSelected}
                helperText={false}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Person />
                        </InputAdornment>
                    )
                }}
            />
            <TextInput
                source="planter"
                variant="outlined"
                onChange={onPlanterSelected}
                helperText={false}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Person />
                        </InputAdornment>
                    )
                }}
            />
            <TextInput
                source="creator"
                variant="outlined"
                onChange={onCreatorSelected}
                helperText={false}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Person />
                        </InputAdornment>
                    )
                }}
            />
            <TextInput
                source="specie"
                variant="outlined"
                onChange={onSpecieSelected}
                helperText={false}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Spa />
                        </InputAdornment>
                    )
                }}
            />
            <TextInput
                source="recognized_specie"
                variant="outlined"
                onChange={onRecognizedSpecieSelected}
                helperText={false}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Spa />
                        </InputAdornment>
                    )
                }}
            />
            <TextInput
                source="location"
                variant="outlined"
                onChange={onLocationSelected}
                helperText={false}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <LocationOn />
                        </InputAdornment>
                    )
                }}
            />
            <UIDateInput source="min_planted_date" variant="outlined" onChange={onMinDateSelected} helperText={false} />
            <UIDateInput source="max_planted_date" variant="outlined" onChange={onMaxDateSelected} helperText={false} />
            <TextInput
                type="number"
                source="min_trunk_diameter"
                variant="outlined"
                onChange={onMinTrunkDiameterSelected}
                helperText={false}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <HideSource />
                        </InputAdornment>
                    )
                }}
            />
            <TextInput
                type="number"
                source="max_trunk_diameter"
                variant="outlined"
                onChange={onMaxTrunkDiameterSelected}
                helperText={false}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <HideSource />
                        </InputAdornment>
                    )
                }}
            />
            <TextInput
                type="number"
                source="min_tree_height"
                variant="outlined"
                onChange={onMinTreeHeightSelected}
                helperText={false}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Height />
                        </InputAdornment>
                    )
                }}
            />
            <TextInput
                type="number"
                source="max_tree_height"
                variant="outlined"
                onChange={onMaxTreeHeightSelected}
                helperText={false}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Height />
                        </InputAdornment>
                    )
                }}
            />
        </FormProvider>
    );
};

const FilterSidebar = () => (
    <Box
        sx={{
            display: {
                xs: "none",
                sm: "block"
            },
            order: -1,
            marginRight: "1em",
            minWidth: "240px",
            width: "240px"
        }}>
        <Card>
            <CardContent>
                <DateFilter />
            </CardContent>
        </Card>
    </Box>
);

const WTreeList = () => {
    const translate = useTranslate();
    const [locale] = useLocaleState();
    return (
        <List exporter={false} aside={<FilterSidebar />} actions={false}>
            <Datagrid
                bulkActionButtons={false}
                sx={{
                    "& .RaDatagrid-headerCell": {
                        fontWeight: "bold"
                    }
                }}
                rowClick="show">
                <TextField source="id" />
                <FunctionField
                    source="order_id"
                    render={record => {
                        if (record?.order_id) {
                            return (
                                <Link onClick={e => e.stopPropagation()} to={`/order/${record.order_id}/show`}>
                                    <TextField source="order_id" />
                                </Link>
                            );
                        } else {
                            return (
                                <Typography variant="subtitle1">{translate("resources.tree.other.noOrder")}</Typography>
                            );
                        }
                    }}
                />
                <TextField source="given_name" sortBy="order.given_name" />
                <TextField source="trunk_diameter" />
                <TextField source="tree_height" />
                <TextField source="canopy_height" />
                <DateField source="planted_date" />
                <FunctionField
                    source="owner"
                    sortBy="owner.username"
                    render={record => {
                        if (record?.owner_id) {
                            return (
                                <Link onClick={e => e.stopPropagation()} to={`/account/${record.owner_id}/show`}>
                                    <TextField source="owner_name" />
                                </Link>
                            );
                        } else {
                            return (
                                <Typography variant="subtitle1">
                                    {translate("resources.tree.other.noAccount")}
                                </Typography>
                            );
                        }
                    }}
                />
                <FunctionField
                    source="planter"
                    sortBy="planter.username"
                    render={record => {
                        if (record?.planter_id) {
                            return (
                                <Link onClick={e => e.stopPropagation()} to={`/account/${record.planter_id}/show`}>
                                    <TextField source="planter_name" />
                                </Link>
                            );
                        } else {
                            return (
                                <Typography variant="subtitle1">
                                    {translate("resources.tree.other.noAccount")}
                                </Typography>
                            );
                        }
                    }}
                />
                <FunctionField
                    source="creator"
                    sortBy="creator.username"
                    render={record => {
                        if (record?.creator_id) {
                            return (
                                <Link onClick={e => e.stopPropagation()} to={`/account/${record.creator_id}/show`}>
                                    <TextField source="creator_name" />
                                </Link>
                            );
                        } else {
                            return (
                                <Typography variant="subtitle1">
                                    {translate("resources.tree.other.noAccount")}
                                </Typography>
                            );
                        }
                    }}
                />
                <FunctionField
                    source="location"
                    sortBy="location.title"
                    render={record => {
                        if (record?.location_id) {
                            return (
                                <Link onClick={e => e.stopPropagation()} to={`/location/${record.location_id}/show`}>
                                    <TextField source={`location_title.${locale}`} />
                                </Link>
                            );
                        } else {
                            return (
                                <Typography variant="subtitle1">
                                    {translate("resources.tree.other.noLocation")}
                                </Typography>
                            );
                        }
                    }}
                />
                <FunctionField
                    source="specie"
                    sortBy="specie.common_name"
                    render={record => {
                        if (record?.specie_id) {
                            return (
                                <Link onClick={e => e.stopPropagation()} to={`/specie/${record.specie_id}/show`}>
                                    <TextField source={`specie_common_name.${locale}`} />
                                </Link>
                            );
                        } else {
                            return (
                                <Typography variant="subtitle1">
                                    {translate("resources.tree.other.noSpecie")}
                                </Typography>
                            );
                        }
                    }}
                />
                <FunctionField
                    source="recognized_specie"
                    sortBy="recognized_specie.common_name"
                    render={record => {
                        if (record?.recognized_specie_id) {
                            return (
                                <Link
                                    onClick={e => e.stopPropagation()}
                                    to={`/specie/${record.recognized_specie_id}/show`}>
                                    <TextField source={`recognized_specie_common_name.${locale}`} />
                                </Link>
                            );
                        } else {
                            return (
                                <Typography variant="subtitle1">
                                    {translate("resources.tree.other.noSpecie")}
                                </Typography>
                            );
                        }
                    }}
                />
            </Datagrid>
        </List>
    );
};

export default WTreeList;
