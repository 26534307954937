import { TextField, BooleanField, NumberField, EmailField, useRecordContext, Labeled } from "react-admin";
import { Avatar, Grid } from "@mui/material";
import { ORIGIN } from "../../../../data/common.js";

const ProfilePicture = () => {
    const record = useRecordContext();
    if (record) {
        return (
            <Avatar
                alt={record?.username}
                src={new URL(record?.image?.path, ORIGIN).toString()}
                sx={{ width: 128, height: 128 }}
            />
        );
    } else {
        return "";
    }
};

const WAccountShowable = () => (
    <Grid container columnSpacing={2} sx={{ p: 2 }}>
        <Grid container item xs={12} justifyContent="center">
            <ProfilePicture />
        </Grid>
        <Grid item xs={12}>
            <Labeled>
                <TextField source="id" fullWidth />
            </Labeled>
        </Grid>
        <Grid item xs={12} md={6}>
            <Labeled>
                <TextField source="username" fullWidth />
            </Labeled>
        </Grid>
        <Grid item xs={12} md={6}>
            <Labeled>
                <EmailField source="email" fullWidth />
            </Labeled>
        </Grid>
        <Grid item xs={12} md={6}>
            <Labeled>
                <TextField source="phone" fullWidth />
            </Labeled>
        </Grid>
        <Grid item xs={12} md={6}>
            <Labeled>
                <TextField source="role" fullWidth />
            </Labeled>
        </Grid>
        <Grid item xs={12} md={6}>
            <Labeled>
                <TextField source="firstname" fullWidth />
            </Labeled>
        </Grid>
        <Grid item xs={12} md={6}>
            <Labeled>
                <TextField source="lastname" fullWidth />
            </Labeled>
        </Grid>
        <Grid item xs={12}>
            <Labeled>
                <TextField source="organization" fullWidth />
            </Labeled>
        </Grid>
        <Grid item xs={12} md={6}>
            <Labeled>
                <BooleanField source="confirmed" />
            </Labeled>
        </Grid>
        <Grid item xs={12} md={6}>
            <Labeled>
                <BooleanField source="phone_confirmed" />
            </Labeled>
        </Grid>
        <Grid item xs={12} md={6}>
            <Labeled>
                <BooleanField source="email_updates" />
            </Labeled>
        </Grid>
        <Grid item xs={12} md={6}>
            <Labeled>
                <BooleanField source="visible" />
            </Labeled>
        </Grid>
        <Grid item xs={12} md={6}>
            <Labeled>
                <NumberField source="total_trees" />
            </Labeled>
        </Grid>
        <Grid item xs={12} md={6}>
            <Labeled>
                <NumberField source="total_orders" />
            </Labeled>
        </Grid>
    </Grid>
);

export default WAccountShowable;
