import { API_URL } from "../common";
import httpClient from "../../services/http";
import BaseDataProvider from "./base";

class InitiativeDataProvider extends BaseDataProvider {
    async getList(resource, params) {
        const { filter, sort } = params;
        const { page, perPage } = params.pagination;
        const url = new URL(`${API_URL}/${resource}`);
        url.search = new URLSearchParams({
            ...filter,
            sort_direction: sort?.order,
            sort_by: sort?.field?.split(".").shift()
        })?.toString();
        const { json } = await httpClient(url, {
            headers: new Headers({
                "x-offset": (page - 1) * perPage,
                "x-count": perPage
            })
        });
        return {
            data: json.initiatives,
            total: json.total_count
        };
    }

    async getOne(resource, params) {
        const { json } = await httpClient(`${API_URL}/${resource}/${params.id}`);
        return {
            data: {
                ...json,
                species: json?.locations?.reduce((acc, curr) => {
                    for (const specie of curr?.species) {
                        if (!acc.find(elem => elem.id === specie.id)) {
                            acc.push(specie);
                        }
                    }
                    return acc;
                }, [])
            }
        };
    }

    async update(resource, params) {
        const { image, location, ...payload } = params.data;
        if (params.meta?.file && image) {
            const form = new FormData();
            form.append("image", image);
            const { json } = await httpClient(`${API_URL}/${resource}/${params.id}/image`, {
                method: "POST",
                body: form
            });
            return { data: json };
        } else if (params.meta?.location) {
            const { json } = await httpClient(`${API_URL}/${resource}/${params.id}/location`, {
                method: "POST",
                body: JSON.stringify({ id: location?.id })
            });
            return { data: json };
        } else {
            await httpClient(`${API_URL}/${resource}/${params.id}`, {
                method: "PUT",
                body: JSON.stringify(payload)
            });
            return { data: payload };
        }
    }

    async delete(resource, params) {
        if (params.meta?.imageId) {
            await httpClient(`${API_URL}/${resource}/${params.id}/image/${params.meta?.imageId}`, {
                method: "DELETE"
            });
        } else if (params.meta?.locationId) {
            await httpClient(`${API_URL}/${resource}/${params.id}/location/${params.meta?.locationId}`, {
                method: "DELETE"
            });
        } else {
            await httpClient(`${API_URL}/${resource}/${params.id}`, {
                method: "DELETE"
            });
        }
        return { data: params };
    }
}

export default InitiativeDataProvider;
