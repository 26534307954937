import { TextField, BooleanField, EmailField, Labeled, DateField } from "react-admin";
import { Grid } from "@mui/material";

const WFeedbackShowable = () => (
    <Grid container columnSpacing={2} sx={{ p: 2 }}>
        <Grid item xs={12}>
            <Labeled>
                <TextField source="id" fullWidth />
            </Labeled>
        </Grid>
        <Grid item xs={12} md={6}>
            <Labeled>
                <TextField source="name" fullWidth />
            </Labeled>
        </Grid>
        <Grid item xs={12} md={6}>
            <Labeled>
                <EmailField source="email" fullWidth />
            </Labeled>
        </Grid>
        <Grid item xs={12} md={6}>
            <Labeled>
                <DateField source="created_at" fullWidth />
            </Labeled>
        </Grid>
        <Grid item xs={12} md={6}>
            <Labeled>
                <BooleanField source="resolved" fullWidth />
            </Labeled>
        </Grid>
        <Grid item xs={12}>
            <Labeled>
                <TextField source="text" fullWidth />
            </Labeled>
        </Grid>
    </Grid>
);

export default WFeedbackShowable;
