import { Edit } from "react-admin";
import WTreeEditable from "./WTreeEditable";

const WTreeEdit = () => (
    <Edit>
        <WTreeEditable />
    </Edit>
);

export default WTreeEdit;
