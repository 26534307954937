import { Edit } from "react-admin";
import WOrderEditable from "./WOrderEditable";

const WOrderEdit = () => (
    <Edit>
        <WOrderEditable />
    </Edit>
);

export default WOrderEdit;
