import {
    TextField,
    TabbedShowLayout,
    Tab,
    NumberField,
    TranslatableFields,
    Labeled,
    useRecordContext,
    BooleanField,
    useTranslate,
    DateField,
    RecordContextProvider,
    FunctionField,
    Link
} from "react-admin";
import { ImageList, ImageListItem, Grid, Typography } from "@mui/material";
import { supportedLocales } from "../../../../data/providers/i18n";
import { ORIGIN } from "../../../../data/common.js";
import noImage from "../../../../assets/images/no_tree_photo.svg";
import UIJsonField from "../../../ui/fields/UIJsonField";
import UIMapboxField from "../../../ui/fields/UIMapboxField";
import WAccountShowable from "../account/WAccountShowable";
import WSpecieShowable from "../specie/WSpecieShowable";
import WLocationShowable from "../location/WLocationShowable";

const WTreeShowable = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    return (
        <TabbedShowLayout syncWithLocation={false}>
            <Tab label="resources.tree.tabs.common">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={6}>
                        <Labeled source="id" fullWidth>
                            <TextField source="id" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled source="order_id" fullWidth>
                            <FunctionField
                                source="order_id"
                                render={record => {
                                    if (record?.order_id) {
                                        return (
                                            <Link
                                                onClick={e => e.stopPropagation()}
                                                to={`/order/${record.order_id}/show`}>
                                                <TextField source="order_id" />
                                            </Link>
                                        );
                                    } else {
                                        return (
                                            <Typography variant="subtitle1">
                                                {translate("resources.tree.other.noOrder")}
                                            </Typography>
                                        );
                                    }
                                }}
                            />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled source="planted_date" fullWidth>
                            <DateField source="planted_date" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled source="given_name" fullWidth>
                            <TextField source="given_name" />
                        </Labeled>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Labeled source="trunk_diameter" fullWidth>
                            <NumberField source="trunk_diameter" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled source="tree_height" fullWidth>
                            <NumberField source="tree_height" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled source="canopy_height" fullWidth>
                            <NumberField source="canopy_height" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled source="hidden" fullWidth>
                            <BooleanField source="hidden" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled source="story" fullWidth>
                            <TranslatableFields locales={supportedLocales}>
                                <TextField source="story" />
                            </TranslatableFields>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled source="eco_benefits" fullWidth>
                            <UIJsonField source="eco_benefits" />
                        </Labeled>
                    </Grid>
                </Grid>
            </Tab>
            <Tab label="resources.tree.tabs.geolocation">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        <Labeled source="point" fullWidth>
                            <UIMapboxField source="point" type="point" />
                        </Labeled>
                    </Grid>
                </Grid>
            </Tab>
            <Tab label="resources.tree.tabs.images">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        {record?.images?.length > 0 ? (
                            <Labeled source="images">
                                <ImageList variant="masonry" cols={2} gap={8}>
                                    {record.images.map(image => (
                                        <ImageListItem key={image.id}>
                                            <img
                                                src={new URL(image.path, ORIGIN).toString()}
                                                alt=""
                                                loading="lazy"
                                                onError={e => (e.target.src = noImage)}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Labeled>
                        ) : (
                            <Labeled source="images">
                                <Typography variant="subtitle1">
                                    {translate("resources.tree.other.noImages")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                </Grid>
            </Tab>
            <Tab label="resources.tree.tabs.users">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={6}>
                        {record?.owner ? (
                            <Labeled source="owner">
                                <RecordContextProvider value={record?.owner}>
                                    <WAccountShowable />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="owner">
                                <Typography variant="subtitle1">
                                    {translate("resources.otreerder.other.noAccount")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {record?.planter ? (
                            <Labeled source="planter" fullWidth>
                                <RecordContextProvider value={record?.planter}>
                                    <WAccountShowable />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="planter">
                                <Typography variant="subtitle1">
                                    {translate("resources.tree.other.noAccount")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                </Grid>
            </Tab>
            <Tab label="resources.tree.tabs.location">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        {record?.location ? (
                            <Labeled source="location" fullWidth>
                                <RecordContextProvider value={record?.location}>
                                    <WLocationShowable />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="location">
                                <Typography variant="subtitle1">
                                    {translate("resources.tree.other.noLocation")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                </Grid>
            </Tab>
            <Tab label="resources.tree.tabs.species">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={6}>
                        {record?.specie ? (
                            <Labeled source="specie" fullWidth>
                                <RecordContextProvider value={record?.specie}>
                                    <WSpecieShowable />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="specie">
                                <Typography variant="subtitle1">
                                    {translate("resources.tree.other.noSpecie")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {record?.recognized_specie ? (
                            <Labeled source="recognized_specie" fullWidth>
                                <RecordContextProvider value={record?.recognized_specie}>
                                    <WSpecieShowable />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="recognized_specie">
                                <Typography variant="subtitle1">
                                    {translate("resources.tree.other.noSpecie")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                </Grid>
            </Tab>
        </TabbedShowLayout>
    );
};

export default WTreeShowable;
