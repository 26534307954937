import {
    TextField,
    TabbedShowLayout,
    Tab,
    NumberField,
    TranslatableFields,
    Labeled,
    useRecordContext,
    RichTextField,
    ArrayField,
    Datagrid,
    useLocaleState,
    useTranslate
} from "react-admin";
import { ImageList, ImageListItem, Grid, Typography } from "@mui/material";
import { supportedLocales } from "../../../../data/providers/i18n";
import { ORIGIN } from "../../../../data/common.js";
import noImage from "../../../../assets/images/no_tree_photo.svg";

const WOrderShowable = () => {
    const record = useRecordContext();
    const [locale] = useLocaleState();
    const translate = useTranslate();
    return (
        <TabbedShowLayout syncWithLocation={false}>
            <Tab label="resources.specie.tabs.common">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={6}>
                        <Labeled source="id" fullWidth>
                            <TextField source="id" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled source="price" fullWidth>
                            <NumberField source="price" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled source="common_name" fullWidth>
                            <TranslatableFields locales={supportedLocales}>
                                <TextField source="common_name" />
                            </TranslatableFields>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled source="scientific_name" fullWidth>
                            <TranslatableFields locales={supportedLocales}>
                                <TextField source="scientific_name" />
                            </TranslatableFields>
                        </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled source="description" fullWidth>
                            <TranslatableFields locales={supportedLocales}>
                                <RichTextField source="description" />
                            </TranslatableFields>
                        </Labeled>
                    </Grid>
                </Grid>
            </Tab>
            {record?.images && (
                <Tab label="resources.specie.tabs.images">
                    <Grid container columnSpacing={2} sx={{ p: 2 }}>
                        <Grid item xs={12}>
                            {record.images?.length > 0 ? (
                                <Labeled source="images">
                                    <ImageList variant="masonry" cols={2} gap={8}>
                                        {record.images.map(image => (
                                            <ImageListItem key={image.id}>
                                                <img
                                                    src={new URL(image.path, ORIGIN).toString()}
                                                    alt=""
                                                    loading="lazy"
                                                    onError={e => (e.target.src = noImage)}
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                </Labeled>
                            ) : (
                                <Labeled source="images">
                                    <Typography variant="subtitle1">
                                        {translate("resources.specie.other.noImages")}
                                    </Typography>
                                </Labeled>
                            )}
                        </Grid>
                    </Grid>
                </Tab>
            )}
            <Tab label="resources.specie.tabs.locations">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        {record?.locations?.length > 0 ? (
                            <Labeled source="locations" fullWidth>
                                <ArrayField source="locations">
                                    <Datagrid
                                        bulkActionButtons={false}
                                        sx={{
                                            "& .RaDatagrid-headerCell": {
                                                fontWeight: "bold"
                                            }
                                        }}>
                                        <TextField source="id" />
                                        <TextField label="resources.location.fields.title" source={`title.${locale}`} />
                                    </Datagrid>
                                </ArrayField>
                            </Labeled>
                        ) : (
                            <Labeled source="locations">
                                <Typography variant="subtitle1">
                                    {translate("resources.specie.other.noLocations")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                </Grid>
            </Tab>
        </TabbedShowLayout>
    );
};

export default WOrderShowable;
