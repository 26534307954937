import { Edit } from "react-admin";
import WAccountEditable from "./WAccountEditable";

const WAccountEdit = () => (
    <Edit>
        <WAccountEditable />
    </Edit>
);

export default WAccountEdit;
