import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useInput, useTranslate, useResourceContext, useLocaleState, required } from "react-admin";
import ru from "date-fns/locale/ru";
import en from "date-fns/locale/en-US";

const translations = { ru, en };

const UIDateInput = props => {
    const { onChange, onBlur, showTime, ...rest } = props;
    const {
        field,
        fieldState: { isTouched, invalid },
        formState: { isSubmitted, errors },
        isRequired
    } = useInput({
        validate: required(),
        onChange,
        onBlur,
        ...props
    });
    const [locale] = useLocaleState();
    const resource = useResourceContext();
    const translate = useTranslate();
    const i18nRoute = `resources.${resource}.fields.${field.name}`;
    let label = translate(i18nRoute);
    label = label === i18nRoute ? field.name : label;
    label = props.label ? props.label : label;
    label = isRequired ? label + "\u2009*" : label;
    const hasError = !!errors[field.name];
    const error = hasError ? errors[field.name]?.message : "";
    const Picker = showTime ? DateTimePicker : DatePicker;
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={translations[locale]}>
            <Picker
                {...field}
                label={label}
                value={field.value ?? null}
                renderInput={props => (
                    <TextField
                        sx={{ height: hasError ? "60px" : "44px", position: "relative" }}
                        helperText={error}
                        {...rest}
                        {...props}
                        error={((isTouched || isSubmitted) && invalid) || hasError}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default UIDateInput;
