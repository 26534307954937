import { Edit } from "react-admin";
import WLocationEditable from "./WLocationEditable";

const WLocationEdit = () => (
    <Edit>
        <WLocationEditable />
    </Edit>
);

export default WLocationEdit;
