import {
    Datagrid,
    TextField,
    List,
    FilterLiveSearch,
    EmailField,
    DateField,
    BooleanField,
    SimpleShowLayout
} from "react-admin";
import { Card, CardContent, Box } from "@mui/material";

const FilterSidebar = () => (
    <Box
        sx={{
            display: {
                xs: "none",
                sm: "block"
            },
            order: -1,
            width: "15em",
            marginRight: "1em"
        }}>
        <Card>
            <CardContent>
                <FilterLiveSearch
                    source="name"
                    variant="outlined"
                    label="resources.feedback.fields.name"
                    sx={{ minWidth: "200px" }}
                />
                <FilterLiveSearch
                    source="email"
                    variant="outlined"
                    label="resources.feedback.fields.email"
                    sx={{ minWidth: "200px" }}
                />
                <FilterLiveSearch
                    source="text"
                    variant="outlined"
                    label="resources.feedback.fields.text"
                    sx={{ minWidth: "200px" }}
                />
            </CardContent>
        </Card>
    </Box>
);

const TextShow = () => (
    <SimpleShowLayout>
        <TextField source="text" />
    </SimpleShowLayout>
);

const WFeedbackList = () => (
    <List exporter={false} aside={<FilterSidebar />} actions={false}>
        <Datagrid
            expand={<TextShow />}
            bulkActionButtons={false}
            sx={{
                "& .RaDatagrid-headerCell": {
                    fontWeight: "bold"
                }
            }}
            rowClick="show">
            <TextField source="id" />
            <TextField source="name" />
            <EmailField source="email" />
            <DateField source="created_at" />
            <BooleanField source="resolved" />
        </Datagrid>
    </List>
);

export default WFeedbackList;
