import { Edit } from "react-admin";
import WFeedbackEditable from "./WFeedbackEditable";

const WFeedbackEdit = () => (
    <Edit>
        <WFeedbackEditable />
    </Edit>
);

export default WFeedbackEdit;
