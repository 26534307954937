import { MapContainer, TileLayer, FeatureGroup, GeoJSON, useMap } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { useInput } from "react-admin";
import { useEffect, useState } from "react";
import hash from "object-hash";

import * as turf from "@turf/turf";

import L from "leaflet";

const UIMapInput = props => {
    const { controlPoint, controlPolygon, ...rest } = props;

    const {
        field: { onChange, value }
    } = useInput(rest);

    const onCreated = data => {
        const geoJSON = data.layer.toGeoJSON();
        if (geoJSON?.geometry?.type === "Polygon") {
            const polygon = turf.polygon(geoJSON.geometry.coordinates);
            const area = turf.area(polygon);
            onChange({ ...geoJSON, properties: { area: area } });
        } else {
            onChange(geoJSON);
        }
    };

    const onDeleted = () => {
        onChange(null);
    };

    const ChangeMapView = ({ coords }) => {
        const map = useMap();
        map.setView(coords, map.getZoom());
        return null;
    };

    const [center, setCenter] = useState([26.422719266129025, 50.08891832397754]);

    useEffect(() => {
        if (value?.geometry?.type === "Polygon") {
            const polygon = turf.polygon(value.geometry.coordinates);
            const centroid = turf.centroid(polygon);
            setCenter([centroid.geometry.coordinates[1], centroid.geometry.coordinates[0]]);
        } else if (value?.geometry?.type === "Point") {
            setCenter([value?.geometry.coordinates[1], value?.geometry.coordinates[0]]);
        }
    }, [value]);

    L.EditToolbar.Delete.include({
        enable: function () {
            this.options.featureGroup.clearLayers();
            onDeleted();
        }
    });

    return (
        <MapContainer
            key={Math.random()}
            center={center}
            zoom={12}
            scrollWheelZoom={false}
            style={{ height: "500px", width: "100%", flexGrow: 1 }}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <FeatureGroup>
                <EditControl
                    position="topright"
                    onCreated={onCreated}
                    draw={{
                        rectangle: false,
                        circle: false,
                        marker: !!controlPoint,
                        polyline: false,
                        circlemarker: false,
                        polygon: !!controlPolygon
                    }}
                    edit={{
                        edit: false
                    }}
                />
                {value && <GeoJSON key={hash(value)} data={value} />}
            </FeatureGroup>
            <ChangeMapView coords={center} />
        </MapContainer>
    );
};

export default UIMapInput;
