import {
    TextInput,
    TabbedForm,
    FormTab,
    TranslatableInputs,
    NumberInput,
    useUpdate,
    useDelete,
    useNotify,
    useRefresh,
    TextField,
    useRecordContext,
    Labeled,
    FileInput,
    ReferenceArrayInput,
    useTranslate,
    FunctionField,
    Button,
    Link,
    BooleanInput,
    RecordContextProvider,
    useGetOne,
    Loading
} from "react-admin";
import { Fragment } from "react";
import { Grid, ImageList, ImageListItem, ImageListItemBar, IconButton, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { supportedLocales } from "../../../../data/providers/i18n";
import { ORIGIN } from "../../../../data/common";
import noImage from "../../../../assets/images/no_tree_photo.svg";

import WAccountList from "../../lists/WAccountList";
import WSpecieList from "../../lists/WSpecieList";
import WOrderList from "../../lists/WOrderList";

import UIMapInput from "../../../ui/inputs/UIMapInput";
import UIJsonInput from "../../../ui/inputs/UIJsonInput";
import WAccountShowable from "../account/WAccountShowable";
import WLocationShowable from "../location/WLocationShowable";
import WSpecieShowable from "../specie/WSpecieShowable";
import WOrderShowable from "../order/WOrderShowable";
import UIDateInput from "../../../ui/inputs/UIDateInput";

const TreeOrderShowable = ({ record }) => {
    const {
        data: order,
        isLoading,
        error
    } = useGetOne("order", {
        id: record.order_id
    });
    if (isLoading) {
        return <Loading />;
    }
    if (error) {
        return <p>ERROR</p>;
    }
    return (
        <RecordContextProvider value={order}>
            <WOrderShowable />
        </RecordContextProvider>
    );
};

const WTreeEditable = () => {
    const translate = useTranslate();
    const record = useRecordContext();

    const notify = useNotify();
    const refresh = useRefresh();
    const [update] = useUpdate();
    const [deleteOne] = useDelete();

    const onImageDelete = async id => {
        await deleteOne(
            "tree",
            {
                id: record?.id,
                meta: { imageId: id }
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("ra.notification.updated", {
                        messageArgs: { smart_count: 1 }
                    });
                },
                onError: error => {
                    if (error.status === 400) {
                        notify("other.errors.badRequest", {
                            type: "warning",
                            messageArgs: {
                                reason: error.body?.detail ?? error.message
                            }
                        });
                    } else {
                        notify("other.errors.empty", {
                            type: "warning",
                            messageArgs: {
                                reason: error.message
                            }
                        });
                    }
                }
            }
        );
    };

    const onFileChange = async file => {
        if (file && record?.id) {
            await update(
                "tree",
                {
                    id: record?.id,
                    meta: { file: true },
                    data: { image: file }
                },
                {
                    onSuccess: () => {
                        refresh();
                        notify("ra.notification.updated", {
                            messageArgs: { smart_count: 1 }
                        });
                    },
                    onError: error => {
                        if (error.status === 400) {
                            notify("other.errors.badRequest", {
                                type: "warning",
                                messageArgs: {
                                    reason: error.body?.detail ?? error.message
                                }
                            });
                        } else {
                            notify("other.errors.empty", {
                                type: "warning",
                                messageArgs: {
                                    reason: error.message
                                }
                            });
                        }
                    }
                }
            );
        }
    };

    const onPlanterSelected = async planter => {
        await update(
            "tree",
            {
                id: record?.id,
                meta: { planter: true },
                data: { planter: planter }
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("ra.notification.updated", {
                        messageArgs: { smart_count: 1 }
                    });
                },
                onError: error => {
                    if (error.status === 400) {
                        notify("other.errors.badRequest", {
                            type: "warning",
                            messageArgs: {
                                reason: error.body?.detail ?? error.message
                            }
                        });
                    } else {
                        notify("other.errors.empty", {
                            type: "warning",
                            messageArgs: {
                                reason: error.message
                            }
                        });
                    }
                }
            }
        );
    };

    const onPlanterSelectedToDelete = async () => {
        await deleteOne(
            "tree",
            {
                id: record?.id,
                meta: { planter: true }
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("ra.notification.updated", {
                        messageArgs: { smart_count: 1 }
                    });
                },
                onError: error => {
                    if (error.status === 400) {
                        notify("other.errors.badRequest", {
                            type: "warning",
                            messageArgs: {
                                reason: error.body?.detail ?? error.message
                            }
                        });
                    } else {
                        notify("other.errors.empty", {
                            type: "warning",
                            messageArgs: {
                                reason: error.message
                            }
                        });
                    }
                }
            }
        );
    };

    const onOrderSelected = async (order, auto = false) => {
        if (record?.id) {
            await update(
                "tree",
                {
                    id: record?.id,
                    meta: { order: true, auto: auto },
                    data: { order: order }
                },
                {
                    onSuccess: () => {
                        refresh();
                        notify("ra.notification.updated", {
                            messageArgs: { smart_count: 1 }
                        });
                    },
                    onError: error => {
                        if (error.status === 400) {
                            notify("other.errors.badRequest", {
                                type: "warning",
                                messageArgs: {
                                    reason: error.body?.detail ?? error.message
                                }
                            });
                        } else {
                            notify("other.errors.empty", {
                                type: "warning",
                                messageArgs: {
                                    reason: error.message
                                }
                            });
                        }
                    }
                }
            );
        }
    };

    const onOrderSelectedToDelete = async () => {
        await deleteOne(
            "tree",
            {
                id: record?.id,
                meta: { order: true }
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("ra.notification.updated", {
                        messageArgs: { smart_count: 1 }
                    });
                },
                onError: error => {
                    if (error.status === 400) {
                        notify("other.errors.badRequest", {
                            type: "warning",
                            messageArgs: {
                                reason: error.body?.detail ?? error.message
                            }
                        });
                    } else {
                        notify("other.errors.empty", {
                            type: "warning",
                            messageArgs: {
                                reason: error.message
                            }
                        });
                    }
                }
            }
        );
    };

    const onSpecieSelected = async specie => {
        if (specie && record?.id) {
            await update(
                "tree",
                {
                    id: record?.id,
                    meta: { specie: true },
                    data: { specie: specie }
                },
                {
                    onSuccess: () => {
                        refresh();
                        notify("ra.notification.updated", {
                            messageArgs: { smart_count: 1 }
                        });
                    },
                    onError: error => {
                        if (error.status === 400) {
                            notify("other.errors.badRequest", {
                                type: "warning",
                                messageArgs: {
                                    reason: error.body?.detail ?? error.message
                                }
                            });
                        } else {
                            notify("other.errors.empty", {
                                type: "warning",
                                messageArgs: {
                                    reason: error.message
                                }
                            });
                        }
                    }
                }
            );
        }
    };

    const onSpecieSelectedToDelete = async () => {
        await deleteOne(
            "tree",
            {
                id: record?.id,
                meta: { specie: true }
            },
            {
                onSuccess: () => {
                    refresh();
                    notify("ra.notification.updated", {
                        messageArgs: { smart_count: 1 }
                    });
                },
                onError: error => {
                    if (error.status === 400) {
                        notify("other.errors.badRequest", {
                            type: "warning",
                            messageArgs: {
                                reason: error.body?.detail ?? error.message
                            }
                        });
                    } else {
                        notify("other.errors.empty", {
                            type: "warning",
                            messageArgs: {
                                reason: error.message
                            }
                        });
                    }
                }
            }
        );
    };

    return (
        <TabbedForm syncWithLocation={false}>
            <FormTab label="resources.tree.tabs.common">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={6}>
                        <Labeled source="id" fullWidth>
                            <TextField source="id" />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Labeled source="order_id" fullWidth>
                            <FunctionField
                                source="order_id"
                                render={record => {
                                    if (record?.order_id) {
                                        return (
                                            <Link
                                                onClick={e => e.stopPropagation()}
                                                to={`/order/${record.order_id}/show`}>
                                                <TextField source="order_id" />
                                            </Link>
                                        );
                                    } else {
                                        return (
                                            <Typography variant="subtitle1">
                                                {translate("resources.tree.other.noOrder")}
                                            </Typography>
                                        );
                                    }
                                }}
                            />
                        </Labeled>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <UIDateInput showTime source="planted_date" variant="outlined" fullWidth />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextInput source="given_name" variant="outlined" fullWidth />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <NumberInput source="trunk_diameter" variant="outlined" fullWidth />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <NumberInput source="tree_height" variant="outlined" fullWidth />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <NumberInput source="canopy_height" variant="outlined" fullWidth />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <BooleanInput source="hidden" variant="outlined" fullWidth />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TranslatableInputs locales={supportedLocales}>
                            <TextInput source="story" variant="outlined" fullWidth />
                        </TranslatableInputs>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <UIJsonInput source="eco_benefits" />
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label="resources.tree.tabs.geolocation">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        <Labeled source="point" fullWidth>
                            <UIMapInput source="point" controlPoint={true} />
                        </Labeled>
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label="resources.tree.tabs.images">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        {record?.images?.length > 0 ? (
                            <Labeled source="images">
                                <ImageList variant="masonry" cols={2} gap={8}>
                                    {record.images.map(image => (
                                        <ImageListItem key={image.id}>
                                            <img
                                                src={new URL(image.path, ORIGIN).toString()}
                                                alt=""
                                                loading="lazy"
                                                onError={e => (e.target.src = noImage)}
                                            />
                                            <ImageListItemBar
                                                title={image.id}
                                                subtitle={image.path}
                                                actionIcon={
                                                    <IconButton
                                                        variant="outlined"
                                                        size="small"
                                                        color="error"
                                                        onClick={() => onImageDelete(image.id)}>
                                                        <Delete />
                                                    </IconButton>
                                                }
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Labeled>
                        ) : (
                            <Labeled source="images">
                                <Typography variant="subtitle1">
                                    {translate("resources.tree.other.noImages")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <FileInput
                            source="uploadFile"
                            onChange={onFileChange}
                            label="resources.tree.fields.uploadImage"
                            accept="image/jpg, image/jpeg, image/png"
                        />
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label="resources.tree.tabs.users">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={6}>
                        {record?.owner ? (
                            <Labeled source="owner">
                                <RecordContextProvider value={record?.owner}>
                                    <WAccountShowable />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="owner">
                                <Typography variant="subtitle1">
                                    {translate("resources.tree.other.noAccount")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {record?.planter ? (
                            <Labeled source="planter" fullWidth>
                                <RecordContextProvider value={record?.planter}>
                                    <WAccountShowable />
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="error"
                                        startIcon={<Delete />}
                                        label="ra.action.delete"
                                        onClick={onPlanterSelectedToDelete}
                                    />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="planter">
                                <Typography variant="subtitle1">
                                    {translate("resources.tree.other.noAccount")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled label="resources.order.other.addUser" sx={{ marginTop: "2em" }}>
                            <ReferenceArrayInput source="user_ids" reference="account">
                                <WAccountList withoutToolbar>
                                    <FunctionField
                                        render={record => (
                                            <Button
                                                label="resources.tree.other.setPlanter"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    onPlanterSelected(record);
                                                }}
                                            />
                                        )}
                                    />
                                </WAccountList>
                            </ReferenceArrayInput>
                        </Labeled>
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label="resources.tree.tabs.location">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        {record?.location ? (
                            <Labeled source="location" fullWidth>
                                <RecordContextProvider value={record?.location}>
                                    <WLocationShowable />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="location">
                                <Typography variant="subtitle1">
                                    {translate("resources.tree.other.noLocation")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label="resources.tree.tabs.species">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12} md={6}>
                        {record?.specie ? (
                            <Labeled source="specie" fullWidth>
                                <RecordContextProvider value={record?.specie}>
                                    <WSpecieShowable />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="specie">
                                <Typography variant="subtitle1">
                                    {translate("resources.tree.other.noSpecie")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {record?.recognized_specie ? (
                            <Labeled source="recognized_specie" fullWidth>
                                <RecordContextProvider value={record?.recognized_specie}>
                                    <WSpecieShowable />
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="error"
                                        startIcon={<Delete />}
                                        label="ra.action.delete"
                                        onClick={onSpecieSelectedToDelete}
                                    />
                                </RecordContextProvider>
                            </Labeled>
                        ) : (
                            <Labeled source="recognized_specie">
                                <Typography variant="subtitle1">
                                    {translate("resources.tree.other.noSpecie")}
                                </Typography>
                            </Labeled>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled label="resources.tree.other.setRecognizedSpecie" sx={{ marginTop: "2em" }}>
                            <ReferenceArrayInput source="specie_ids" reference="specie">
                                <WSpecieList withoutToolbar>
                                    <FunctionField
                                        render={record => (
                                            <Button
                                                label="resources.tree.other.selectSpecie"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    onSpecieSelected(record);
                                                }}
                                            />
                                        )}
                                    />
                                </WSpecieList>
                            </ReferenceArrayInput>
                        </Labeled>
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label="resources.tree.tabs.order">
                <Grid container columnSpacing={2} sx={{ p: 2 }}>
                    <Grid item xs={12}>
                        {record?.order_id ? (
                            <Fragment>
                                <Labeled label="resources.tree.tabs.order" fullWidth>
                                    <TreeOrderShowable record={record} />
                                </Labeled>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    color="error"
                                    startIcon={<Delete />}
                                    label="ra.action.delete"
                                    onClick={onOrderSelectedToDelete}
                                />
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Labeled label="resources.tree.tabs.order" fullWidth>
                                    <Typography variant="subtitle1">
                                        {translate("resources.tree.other.noOrder")}
                                    </Typography>
                                </Labeled>
                                <Button
                                    label="resources.tree.other.autoMatch"
                                    onClick={e => {
                                        e.stopPropagation();
                                        onOrderSelected(null, true);
                                    }}
                                />
                            </Fragment>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Labeled label="resources.tree.other.setOrder" sx={{ marginTop: "2em" }}>
                            <ReferenceArrayInput source="order_ids" reference="order">
                                <WOrderList withoutToolbar>
                                    <FunctionField
                                        render={record => (
                                            <Button
                                                label="resources.tree.other.matchOrder"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    onOrderSelected(record);
                                                }}
                                            />
                                        )}
                                    />
                                </WOrderList>
                            </ReferenceArrayInput>
                        </Labeled>
                    </Grid>
                </Grid>
            </FormTab>
        </TabbedForm>
    );
};

export default WTreeEditable;
