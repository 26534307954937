import { Show } from "react-admin";
import WOrdersShowable from "./WOrderShowable";

const WOrdersShow = () => (
    <Show>
        <WOrdersShowable />
    </Show>
);

export default WOrdersShow;
