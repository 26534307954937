import { Show } from "react-admin";
import WAccountShowable from "./WAccountShowable.jsx";

const WAccountShow = () => {
    return (
        <Show>
            <WAccountShowable />
        </Show>
    );
};

export default WAccountShow;
